import React from "react";
import styled from "styled-components";

import { color, space, typography, layout, border } from "styled-system";

const Default = styled.input`
  ${color}${space}${typography}${layout}${border}
  box-sizing : border-box;
  width: 100%;
  outline: none;
  border: none;
`;

const TextField = props => {
  return (
    <Default type="text" px={2} fontSize="1rem" lineHeight="2rem" {...props} />
  );
};

export default TextField;
