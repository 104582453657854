import styled from "styled-components/macro";
import Box from "components/base/Box";

import { colors } from "theme";

const Text = styled(Box)`
  text-align: ${props => (props.center ? "center" : null)};
  font-weight: ${props => (props.bold ? "bold" : null)};
  display: ${props => (props.inlineBlock ? "inline-block" : null)};
`;

function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  throw new Error("Bad Hex");
}

Text.StyledUnderline = styled(Text)`
  display: inline-block;
  line-height: 1.2;
  position: relative;

  &::before {
    position: absolute;
    bottom: 2px;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: ${props => props.lineWidth || "0.2em"};
    background: ${props => hexToRgbA(colors[props.lineColor], 0.3)};
  }
`;

Text.Ellipsis = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.lines ? props.lines : 1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Text;
