import React from "react";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import Text from "components/base/Text";

export const DrawerBackDrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FabComponent = styled(Fab)`
  position: fixed !important;
  z-index: 1200;
  bottom: 90px;
  right: 10px;
  margin: 0 auto;
`;

export const FabButton = props => (
  <FabComponent color="secondary" aria-label="Add" {...props}>
    <Text color="white">펼치기</Text>
  </FabComponent>
);
