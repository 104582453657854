import React, { useState } from "react";
import gql from "graphql-tag";
import { useLocalStorage } from "lib/hooks";

import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import ModelCard from "components/models/ModelCard";
import ModelBasicCompare from "components/compare/ModelBasicCompare";
import ManufacturerLogo from "components/manufacturers/ManufacturerLogo";

import Query from "graphql/Query";

import paths from "paths";

export const MANUFACTURERS = gql`
  query getManufacrurers {
    manufacturers {
      id
      name
      status
      sortWeight
      models {
        id
        name
        uniqName
        year
        representImage {
          small
        }
        trims {
          id
          price
        }
      }
    }
  }
`;

const Header = props => (
  <Flex p={2} alingItems="baseline" bg="concrete">
    <Text fontWeight="bold" lineHeight="1.8rem" {...props} />
  </Flex>
);

const Description = props => (
  <Text
    mt={-1}
    pl={2}
    fontWeight="normal"
    fontSize="0.9rem"
    lineHeight="1.8rem"
    color="tin"
    {...props}
  />
);

const Main = () => {
  const [trimsToCompare, setTrimsToCompare] = useState([]);
  const [enableTools, _setEnableTools] = useLocalStorage("enableTools", false); // eslint-disable-line no-unused-vars

  return (
    <React.Fragment>
      <Header>
        비교하기
        <Description>
          버튼을 눌러 모델을 선택하고 자세히 비교해보세요!
        </Description>
      </Header>
      <ModelBasicCompare
        p={3}
        trims={trimsToCompare}
        onChangeTrim={(newTrim, oldTrim) => {
          const newTrimsToCompare = trimsToCompare.slice();
          if (oldTrim) {
            const index = newTrimsToCompare.findIndex(t => t.id === oldTrim.id);
            newTrimsToCompare[index] = newTrim;
          } else {
            newTrimsToCompare.push(newTrim);
          }
          setTrimsToCompare(newTrimsToCompare);
        }}
      />
      {trimsToCompare.length > 1 && (
        <Flex px={5} justifyContent="flex-end">
          <Button
            to={`/compare?trimIds=${trimsToCompare[0].id}&trimIds=${trimsToCompare[1].id}`}
            mb={4}
          >
            상세비교
          </Button>
        </Flex>
      )}
      <Header>제조사별 보기</Header>
      <Query useStatic query={MANUFACTURERS}>
        {({ data }) => {
          const manufacturers = data.manufacturers;
          return manufacturers.map(manufacturer => {
            // tool
            if (manufacturer.status === "disabled" && !enableTools) return null;

            return (
              <React.Fragment key={manufacturer.id}>
                <Flex
                  mt={2}
                  px={2}
                  alingItems="baseline"
                  justifyContent="space-between"
                >
                  <Flex alingItems="baseline">
                    <ManufacturerLogo name={manufacturer.name} />
                    <Text fontWeight="bold" lineHeight="1.8rem">
                      {manufacturer.name}
                    </Text>
                  </Flex>
                  <Text
                    pr={2}
                    fontSize="0.9rem"
                    lineHeight="1.8rem"
                    to={paths.manufacturers.models(manufacturer.name)}
                  >
                    더보기
                  </Text>
                </Flex>
                <Flex flexWrap="wrap" justifyContent="space-between" p={3}>
                  {manufacturer.models.slice(0, 2).map(model => (
                    <ModelCard
                      key={model.id}
                      manufacturer={manufacturers[0]}
                      model={model}
                    />
                  ))}
                </Flex>
              </React.Fragment>
            );
          });
        }}
      </Query>
    </React.Fragment>
  );
};

export default Main;
