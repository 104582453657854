import React, { useState } from "react";
import { withRouter } from "react-router";
import Query from "graphql/Query";

import Flex from "components/base/Flex";
import Box from "components/base/Box";

import ModelsTab from "./ModelsTab";
import TrimSelectDrawer from "./TrimSelectDrawer";

import { MANUFACTURERS } from "./queries";
//   const afterSelectPath = this.fromTrimCompare()
//     ? paths.trimCompare()
//     : paths.compare();

//   this.props.history.push({
//     pathname: afterSelectPath,
//     search: stringify(
//       {
//         trimIds: selectedTrims.map(t => t.id),
//         modelId: this.fromTrimCompare()
//           ? selectedTrims[0].model.id
//           : undefined
//       },
//       { arrayFormat: "none" }
//     )
//   });

//   this.props.onCompare(selectedTrims);
// };
const CompareSelect = ({ onSelectTrim, onSelectModel }) => {
  const [selectedModel, selectModel] = useState();

  return (
    <Flex flexDirection="column" pt="60px">
      <Query useStatic query={MANUFACTURERS}>
        {({ data }) => {
          const manufacturers = data.manufacturers;

          return (
            <React.Fragment>
              <Box width="100%">
                <ModelsTab
                  manufacturers={manufacturers}
                  onClickModel={onSelectModel ? onSelectModel : selectModel}
                />
              </Box>
              <TrimSelectDrawer
                isOpen={!!selectedModel}
                model={selectedModel}
                onClose={() => selectModel(null)}
                onClickTrim={onSelectTrim}
              />
            </React.Fragment>
          );
        }}
      </Query>
    </Flex>
  );
};

export default withRouter(CompareSelect);
