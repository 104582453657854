import React from "react";

import Text from "components/base/Text";
import CountTo from "components/base/CountTo";
import { formatCurrency } from "lib/helper";

const UnitText = props => <Text as="span" fontSize="0.7em" {...props} />;
const CurrencyFormat = ({
  amount,
  color = "inherit",
  lineColor,
  lineWidth,
  unit = "￦",
  short = false,
  fontWeight
}) => {
  if (lineColor) {
    return (
      <Text.StyledUnderline
        as="span"
        color={color}
        lineColor={lineColor}
        lineWidth={lineWidth}
      >
        {unit === "￦" && <UnitText>{unit}</UnitText>}
        <Text as="span" fontWeight={fontWeight}>
          {formatCurrency(amount, { short })}
        </Text>
        {unit !== "￦" && <UnitText>{unit}</UnitText>}
      </Text.StyledUnderline>
    );
  }
  return (
    <Text as="span" color={color}>
      {unit === "￦" && <UnitText>{unit}</UnitText>}
      <Text as="span" fontWeight={fontWeight}>
        {formatCurrency(amount, { short })}
      </Text>
      {unit !== "￦" && <UnitText>{unit}</UnitText>}
    </Text>
  );
};

CurrencyFormat.CountTo = ({
  amount,
  color = "inherit",
  lineColor,
  lineWidth,
  unit = "￦",
  countToProps = {},

  fontWeight
}) => {
  if (lineColor) {
    return (
      <Text.StyledUnderline
        as="span"
        color={color}
        lineColor={lineColor}
        lineWidth={lineWidth}
      >
        {unit === "￦" && <UnitText>{unit}</UnitText>}
        <Text as="span" fontWeight={fontWeight}>
          <CountTo
            to={amount}
            duration={0.5}
            formattingFn={formatCurrency}
            {...countToProps}
          />
        </Text>
        {unit !== "￦" && <UnitText>{unit}</UnitText>}
      </Text.StyledUnderline>
    );
  }
  return (
    <Text as="span" color={color}>
      {unit === "￦" && <UnitText>{unit}</UnitText>}
      <Text as="span" fontWeight={fontWeight}>
        <CountTo
          to={amount}
          duration={0.5}
          formattingFn={formatCurrency}
          {...countToProps}
        />
      </Text>
      {unit !== "￦" && <UnitText>{unit}</UnitText>}
    </Text>
  );
};

CurrencyFormat.CountTo.displayName = "CurrencyFormatCountTo";

export default CurrencyFormat;
