const MANUFACTURERS = {
  현대자동차: {
    id: 1,
    logo: require("assets/logos/hyundai.png"),
    logoWhite: require("assets/logos/hyundai_white.png"),
    show: true
  },
  기아자동차: { id: 2, logo: require("assets/logos/kia.png"), show: true },
  쌍용자동차: {
    id: 5,
    logo: require("assets/logos/ssangyong.png"),
    show: true
  },
  르노삼성: { id: 4, logo: require("assets/logos/samsung.png"), show: true },
  쉐보레: { id: 3, logo: require("assets/logos/chevolet.png"), show: true },
  제네시스: { id: 6, logo: require("assets/logos/genesis.png"), show: true },
  벤츠: { id: 7, logo: require("assets/logos/genesis.png") },
  BMW: { id: 8, logo: require("assets/logos/genesis.png") },
  아우디: { id: 9, logo: require("assets/logos/genesis.png") },
  폭스바겐: { id: 10, logo: require("assets/logos/genesis.png") },
  볼보: { id: 11, logo: require("assets/logos/genesis.png") },
  토요타: { id: 12, logo: require("assets/logos/genesis.png") },
  렉서스: { id: 13, logo: require("assets/logos/genesis.png") },
  혼다: { id: 14, logo: require("assets/logos/genesis.png") },
  미니: { id: 15, logo: require("assets/logos/genesis.png") },
  재규어: { id: 16, logo: require("assets/logos/genesis.png") },
  랜드로버: { id: 17, logo: require("assets/logos/genesis.png") },
  테슬라: { id: 18, logo: require("assets/logos/genesis.png") },
  푸조: { id: 19, logo: require("assets/logos/genesis.png") },
  지프: { id: 20, logo: require("assets/logos/genesis.png") },
  포드: { id: 21, logo: require("assets/logos/genesis.png") }
};

export const MANUFACTURERS_BY_ID = {
  1: {
    name: "현대자동차",
    logo: require("assets/logos/hyundai.png"),
    logoWhite: require("assets/logos/hyundai_white.png")
  },
  2: { name: "기아자동차", logo: require("assets/logos/kia.png") },
  5: { name: "쌍용자동차", logo: require("assets/logos/ssangyong.png") },
  4: { name: "르노삼성", logo: require("assets/logos/samsung.png") },
  3: { name: "쉐보레", logo: require("assets/logos/chevolet.png") },
  6: { name: "제네시스", logo: require("assets/logos/genesis.png") },
  7: { name: "벤츠", logo: require("assets/logos/genesis.png") }
};

export const manufacturerName = id =>
  MANUFACTURERS_BY_ID[id] ? MANUFACTURERS_BY_ID[id].name : null;

export default MANUFACTURERS;
