import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import CompareSelect from "./CompareSelect";

export const Title = styled(Typography)`
  flex: 1;
  justify-content: space-between;
`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const CompareSelectDialog = ({ isOpen, onClose, onSelectTrim }) => (
  <Dialog
    fullScreen
    open={isOpen}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <AppBar>
      <Toolbar>
        <Title variant="h6" color="inherit">
          비교할 차량 선택하기
        </Title>
        <IconButton color="inherit" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>

    <CompareSelect
      onSelectTrim={trim => {
        onSelectTrim(trim);
        onClose();
      }}
    />
  </Dialog>
);

export default CompareSelectDialog;
