import gql from "graphql-tag";

export const SPEC_ITEM = gql`
  query getSpecItem($id: ID!) {
    specItem(id: $id) {
      name
      trimIds
      model {
        uniqName
        name
        trims {
          id
          name
          price
        }
        grades {
          id
          name
          trims {
            id
            name
          }
        }
      }
      specGroups {
        trimId
      }
      specDescs {
        content
        specItemId
        specItem {
          name
        }
        image {
          square
          large
        }
      }
    }
  }
`;
