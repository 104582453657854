import React, { useState } from "react";
import { Query } from "react-apollo";
import ReactLoading from "react-loading";

import withUrlQuery from "components/route/withUrlQuery";

import Flex from "components/base/Flex";
import SpecGroupDrawer from "views/ModelCompare/components/SpecGroupDrawer";
import { DrawerBackDrop, FabButton } from "views/ModelCompare/styles";
import CompareTable from "./components/CompareTable";
// import TrimSelectDrawer from "components/compare/CompareSelect/TrimSelectDrawer";
// import SpecItemEditorDialog from "components/admin/SpecItem/SpecItemEditorDialog";

import { MODEL_WITH_TRIMS } from "./TrimCompare.graphql";

const CompareView = ({ modelId, trimIds }) => {
  const [priceDrawerOpen, setPriceDrawerOpen] = useState(false);

  const filterSelectedTrims = model => {
    return model.trims.filter(trim => trimIds.includes(trim.id));
  };

  return (
    <div>
      {priceDrawerOpen && (
        <DrawerBackDrop onClick={() => setPriceDrawerOpen(false)} />
      )}
      <Query
        useStatic
        query={MODEL_WITH_TRIMS}
        variables={{ id: modelId }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Flex
                justifyContent="center"
                alignItems="center"
                width={1}
                mt="200px"
              >
                <ReactLoading type="bars" color="#347EDD" />
              </Flex>
            );
          }
          if (error) return <div>Error</div>;

          const model = data.model;
          const selectedTrims = filterSelectedTrims(model);

          return (
            <React.Fragment>
              <CompareTable model={model} trims={selectedTrims} />
              <SpecGroupDrawer
                model={model}
                trims={selectedTrims}
                isOpen={priceDrawerOpen}
                onToggleDrawer={() => setPriceDrawerOpen(!priceDrawerOpen)}
              />
              {!priceDrawerOpen && (
                <FabButton onClick={() => setPriceDrawerOpen(true)} />
              )}
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

CompareView.defaultProps = {
  trimIds: [],
  groupIds: []
};

const urlQueryWhiteList = ["modelId", "trimIds", "groupIds"];

export default withUrlQuery(urlQueryWhiteList)(CompareView);
