import React from "react";
import { Helmet } from "react-helmet";

import { formatCurrency } from "lib/helper";

const Meta = () => {
  const metaDescription = `국내 모든 차종의 옵션, 가격, 연비 정보를 쉽고 간단하게 살펴보세요. 이 모든 정보를 한눈에 비교해 볼 수도 있습니다.`;

  return (
    <Helmet>
      <title>{`풀옵션 - 차 사기 전엔 풀옵션`}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
};

export const ModelMeta = ({ model }) => {
  const defaultDescription = `${model.manufacturer.name} - ${model.name}(${model.year})의 옵션 및 연비 가격 정보. 또, 추천 트림과 ${model.grades.length}개의 등급 및 ${model.trims.length}개의 트림 정보도 같이 확인하실 수 있습니다.`;

  return (
    <Helmet>
      <title>
        {`풀옵션 - ${model.manufacturer.name} - ${model.name} ${model.year} 트림/옵션 정보`}
      </title>
      <meta
        name="description"
        content={
          model.introText
            ? model.introText.replace(/<[^>]+>/g, "")
            : defaultDescription
        }
      />
    </Helmet>
  );
};

export const CompareMeta = ({ trims }) => {
  const title = trims
    .map(t => `${t.model.name} ${t.grade.name} ${t.name}`)
    .join(" VS ");

  const description = trims.map(
    t =>
      `${t.model.name} ${t.grade.name} ${
        t.name
      } - 가격: ${formatCurrency(t.price, {
        short: true
      })}만원, 선택옵션: ${t.specGroups.map(g => g.name).join(", ")}`
  );

  return (
    <Helmet>
      <title>{`풀옵션 - ${title}`}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Meta;
