import styled from "styled-components";
import Box from "components/base/Box";

const Flex = styled(Box)`
  display: flex;
  justify-content: ${props => (props.center ? "center" : undefined)};
  align-items: ${props => (props.center ? "center" : undefined)};
`;

export default Flex;
