import React, { useState, Fragment } from "react";
import styled from "styled-components";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";

import withUrlQuery from "components/route/withUrlQuery";
import NumberedCheck from "components/base/NumberedCheck";

import SpecItemDrawer from "../SpecItemDrawer";

const PaddedBox = styled(Box)`
  padding: 4px 10px;
`;

const BorderBox = props => (
  <PaddedBox borderBottom="1px solid" borderColor="lightgrey" {...props} />
);

const CompareTable = ({
  trims = [],
  specCategories = [],
  groupIds = [],
  trimSize = 2,
  onSpecItemClick = () => {}
}) => {
  if (!trims || trims.length === 0) return null;

  // const [selectedSpecItem, selectSpecItem] = useState({
  //   id: "2148272723"
  // });
  const [selectedSpecItem, selectSpecItem] = useState(null);

  const renderSpecItemRow = (specCategory, trims, trimSize) => {
    // renderSpecItemBySpecCategory에서 계산해도 되지만, 모든 트림 해당 카테고리에 specItem이 없는경우
    // Row 자체를 렌더링 하지 않기 위해 필요
    const trimSpecItems = trims.map(
      trim => find(trim.specCategories, { id: specCategory.id }).specItems
    );

    if (trimSpecItems.every(specItems => specItems.length === 0)) {
      return null;
    }

    const width = `calc(${100 / trimSize}% - ${20 * trimSize}px)`;

    // 임시로 loop 대신 인덱스를 박아놓음
    return (
      <Flex flexDirection="row" key={specCategory.id}>
        <BorderBox fontSize="0.7rem" width="80px" color="stone">
          {specCategory.name}
        </BorderBox>
        {new Array(trimSize).fill().map((_, index) => (
          <BorderBox key={index} fontSize="0.8rem" style={{ width }}>
            {renderSpecItemBySpecCategory(trimSpecItems, index, trims[index])}
          </BorderBox>
        ))}
      </Flex>
    );
  };

  const renderSpecItemBySpecCategory = (trimSpecItems, trimIndex, trim) => {
    if (isEmpty(trimSpecItems[trimIndex])) {
      return "-";
    }

    // 기본 옵션, 선택 옵션 순으로 정렬
    const specItemsByTrim = trimSpecItems[trimIndex].sort((a, b) =>
      b.specGroups.length > 0 &&
      !b.specGroups.some(g => groupIds.includes(g.id))
        ? -1
        : 1
    );

    return (
      <Box>
        {specItemsByTrim.map(specItem => {
          const trimGroups = filter(
            specItem.specGroups,
            g => g.trimId === trim.id
          );
          const isActive =
            trimGroups.length < 1 ||
            trimGroups.some(g => groupIds.includes(g.id));

          return (
            <Fragment key={specItem.id}>
              {/* <Text
                color={isActive ? "inherit" : "gray58"}
                // onClick={() => this.props.onSpecItemClick({ specItem, trim })}
              >
                {specItem.specStandards.map(specStandard => (
                  <div key={specStandard.id}>{specStandard.name}</div>
                ))}
              </Text> */}
              <Box
                color={isActive ? "inherit" : "gray58"}
                py={1}
                fontSize="0.8rem"
                position="relative"
                // onClick={e => {
                //   // 선택 가능한 옵션 그룹이 2개 이상이면 각각 클릭이 되어야 함
                //   if (trimGroups.length === 1) {
                //     e.preventDefault();
                //     this.props.onSpecItemClick({
                //       specItem,
                //       trim,
                //       group: trimGroups[0],
                //       checked: !groupIds.includes(trimGroups[0].id)
                //     });
                //   }
                // }}
              >
                {trimGroups.map(group => (
                  <span key={group.id}>
                    <NumberedCheck
                      label={group.name}
                      number={
                        find(trim.specGroups, g => g.id === group.id).index
                      }
                      theme={trimIndex + 1}
                      shape="circle"
                      showAs="inline"
                      checked={groupIds.includes(group.id)}
                      size={12}
                      spanStyle={{ marginTop: "2px" }}
                      onChange={checked => {
                        onSpecItemClick({
                          specItem,
                          trim,
                          group,
                          checked
                        });
                      }}
                    />
                  </span>
                ))}
                <span>{specItem.name}</span>
                {specItem.totalCommentsCount > 0 && (
                  <Flex center style={{ display: "inline-flex" }}>
                    <Icon
                      className="far fa-comment-dots"
                      color="tin"
                      ml={1}
                      mr="2px"
                    />
                    <Text mb="2px" color="tin" fontSize="0.8em">
                      {specItem.totalCommentsCount}
                    </Text>
                  </Flex>
                )}
                {/* 선택옵션 클릭(터치)를 최대한 가리지 않고 나머지 영역을 클릭 영억으로 잡아 사용자가 불편할수 있는 부분을 최소화 함 */}
                <Box
                  position="absolute"
                  style={{
                    width:
                      trimGroups.length > 0
                        ? `calc(100% - ${trimGroups.length * 25}px)`
                        : "100%",
                    height: "100%",
                    right: 0,
                    top: 0
                  }}
                  onClick={() => selectSpecItem(specItem)}
                />
              </Box>
            </Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <Box pb={5}>
      <Box>
        {specCategories.map(firstSpecCategory => (
          <Fragment key={firstSpecCategory.id}>
            <BorderBox fontSize="0.8rem" bg="mercury">
              {firstSpecCategory.name}
            </BorderBox>
            {firstSpecCategory.children.map(specCategory =>
              renderSpecItemRow(specCategory, trims, trimSize)
            )}
          </Fragment>
        ))}
        <SpecItemDrawer
          specItem={selectedSpecItem}
          onClose={() => selectSpecItem(null)}
        />
      </Box>
    </Box>
  );
};

const urlQueryWhiteList = ["groupIds"];

export default withUrlQuery(urlQueryWhiteList)(CompareTable);
