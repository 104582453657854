import React from "react";
import MUIIcon from "@material-ui/core/Icon";

import Flex from "components/base/Flex";

const imageIcons = {
  audience: require("assets/icons/audience.png"),
  carEngine: require("assets/icons/car-engine.png"),
  wheel: require("assets/icons/wheel.png"),
  dispenser: require("assets/icons/dispenser.png"),
  engine: require("assets/icons/engine.png"),
  fuel: require("assets/icons/fuel.png"),
  // kakaoTalk: require("assets/icons/kakao_talk.png"),
  kakaoTalk: require("assets/icons/kakao_talk2.png")
};

const Icon = ({ className, fontSize = "inherit", ...rest }) => (
  <Flex alignItems="center" {...rest}>
    <MUIIcon className={className} style={{ fontSize }} />
  </Flex>
);

Icon.Image = ({ name, size = "24px", ...rest }) => (
  <Flex alignItems="center" {...rest}>
    <img alt={name} src={imageIcons[name]} width={size} height={size} />
  </Flex>
);

Icon.Image.displayName = "ImageIcon";

export default Icon;
