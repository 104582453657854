import gql from "graphql-tag";

import { fragments as combination } from "graphql/queries/combination.graphql";

export const CREATE_POST = gql`
  mutation CreatePost($title: String!, $content: String!, $comparisonId: ID!) {
    createPost(
      input: { title: $title, content: $content, comparisonId: $comparisonId }
    ) {
      post {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_POST = gql`
  mutation UpdatePost(
    $id: ID!
    $title: String!
    $content: String!
    $comparisonId: ID!
  ) {
    updatePost(
      input: {
        id: $id
        title: $title
        content: $content
        comparisonId: $comparisonId
      }
    ) {
      post {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const COMPARISON = gql`
  query Comparision($id: ID!) {
    comparison(id: $id) {
      combination1 {
        ...CombinationFields
      }
      combination2 {
        ...CombinationFields
      }
    }
  }

  ${combination.ComparisonCombination}
`;
