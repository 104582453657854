import React, { useReducer, useEffect } from "react";
import storage from "local-storage-fallback";

let reducer = (info, newInfo) => {
  if (newInfo === null) {
    storage.removeItem("temporaryInfo");
    return initialState;
  }
  return { ...info, ...newInfo };
};

const initialState = {
  writingPost: {},
  writingComment: {}
};

const localState = JSON.parse(storage.getItem("temporaryInfo"));

const TemporaryInfoContext = React.createContext();

function TemporaryInfoProvider(props) {
  const [info, setInfo] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    storage.setItem("temporaryInfo", JSON.stringify(info));
  }, [info]);

  return (
    <TemporaryInfoContext.Provider value={{ info, setInfo }}>
      {props.children}
    </TemporaryInfoContext.Provider>
  );
}

export { TemporaryInfoContext, TemporaryInfoProvider };
