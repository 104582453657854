import React, { Component, Fragment } from "react";
import filter from "lodash/filter";
import { Flex } from "rebass";
import Button from "@material-ui/core/Button";

import Text from "components/base/Text";
import CurrencyFormat from "components/base/CurrencyFormat";

import withUrlQuery from "components/route/withUrlQuery";

import NumberedCheck from "components/base/NumberedCheck";
import BottomDrawer from "../BottomDrawer";

import {
  SpecDrawerComponent,
  CurrentPrice,
  GroupPrice,
  BasePrice,
  PriceDiff,
  Trims,
  TrimComponent,
  Groups,
  SpecSpecItems,
  SpecSpecItem,
  SectionHeader,
  Footer
} from "./styles";

class SpecGroupDrawer extends Component {
  static defaultProps = {
    trims: [],
    groupIds: []
  };

  handleGroupCheckChanged = (group, checked) => {
    const { groupIds, updateUrlQueries } = this.props;

    const newGroupIds = [groupIds].flat();

    if (checked) {
      updateUrlQueries({ groupIds: newGroupIds.concat([group.id]) });
    } else {
      updateUrlQueries({ groupIds: newGroupIds.filter(id => id !== group.id) });
    }
  };

  renderTrimsPrices = trims => {
    const { groupIds, isOpen } = this.props;

    const trimPrices = trims.map(trim => {
      const groupPrice = filter(trim.specGroups, g => groupIds.includes(g.id))
        .map(g => g.price)
        .reduce((sum, x) => sum + x, 0);

      return {
        id: trim.id,
        totalPrice: trim.price + groupPrice,
        groupPrice,
        price: trim.price
      };
    });

    let lowerstPrice = trimPrices.reduce(
      (min, trim) =>
        min === 0 || min > trim.totalPrice ? trim.totalPrice : min,
      0
    );

    return (
      <React.Fragment>
        {trimPrices.map(trim => (
          <TrimComponent key={trim.id}>
            <CurrentPrice>
              <CurrencyFormat.CountTo
                amount={trim.totalPrice}
                countToProps={{ duration: 0.5 }}
                lineColor="tin"
              />
            </CurrentPrice>
            {isOpen && (
              <Fragment>
                <GroupPrice>
                  선택사양 가격 : <CurrencyFormat amount={trim.groupPrice} />
                </GroupPrice>
                <BasePrice>
                  기본가격 : <CurrencyFormat amount={trim.price} />
                </BasePrice>
              </Fragment>
            )}
            {lowerstPrice < trim.totalPrice && (
              <PriceDiff>
                + <CurrencyFormat amount={trim.totalPrice - lowerstPrice} />
              </PriceDiff>
            )}
          </TrimComponent>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const {
      model,
      trims,
      isOpen,
      onToggleDrawer,
      clickedSpecItem
    } = this.props;
    // from withUrlQuery
    const { groupIds } = this.props;
    return (
      <BottomDrawer isOpen={isOpen} onClose={() => onToggleDrawer(false)}>
        <SpecDrawerComponent>
          <Trims>
            {trims.map(trim => (
              <Text key={trim.id} flex="1" textAlign="center">
                <Text fontSize={1} color="light" fontWeight="bold">
                  {model ? model.name : trim.model.name} ({trim.grade.name})
                </Text>
                <Text fontSize={2} color="light">
                  {trim.name}
                </Text>
              </Text>
            ))}
          </Trims>
          <Trims>{this.renderTrimsPrices(trims)}</Trims>
          <SectionHeader p="10px" align="center">
            선택사양
          </SectionHeader>
          {isOpen && (
            <Trims>
              {trims.map((trim, trimIdx) => (
                <TrimComponent key={trim.id}>
                  {trim.specGroups.map(group => (
                    <Groups key={group.id}>
                      <NumberedCheck
                        label={group.name}
                        number={group.index}
                        theme={trimIdx + 1}
                        shape="circle"
                        onChange={checked =>
                          this.handleGroupCheckChanged(group, checked)
                        }
                        checked={groupIds.includes(group.id)}
                      />
                      <Flex justifyContent="flex-end">
                        <CurrencyFormat amount={group.price} />
                      </Flex>
                      <SpecSpecItems>
                        {
                          "" /* Glow 효과를 주기 위함. 필요없다면 span 만 있으면 된다 */
                        }
                        {group.specItems.map(specItem => (
                          <Fragment key={specItem.id}>
                            {clickedSpecItem &&
                              clickedSpecItem.id === specItem.id && (
                                <SpecSpecItem>{specItem.name},</SpecSpecItem>
                              )}
                            {(!clickedSpecItem ||
                              clickedSpecItem.id !== specItem.id) && (
                              <span>{specItem.name},</span>
                            )}
                          </Fragment>
                        ))}
                      </SpecSpecItems>
                    </Groups>
                  ))}
                </TrimComponent>
              ))}
            </Trims>
          )}
        </SpecDrawerComponent>
        {isOpen && (
          <Footer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onToggleDrawer(false)}
            >
              닫기
            </Button>
          </Footer>
        )}
      </BottomDrawer>
    );
  }
}

const urlQueryWhiteList = ["groupIds"];

export default withUrlQuery(urlQueryWhiteList)(SpecGroupDrawer);
