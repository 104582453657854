import React from "react";

import Query from "graphql/Query";

import Box from "components/base/Box";
import Text from "components/base/Text";

import Cover from "./Cover";
import GradesTable from "./GradesTable";
import Comments from "./Comments";

import { SPEC_ITEM } from "../queries";

const SpecDetail = ({ specDesc = null, specItemId }) => {
  return (
    <Box bg="solitude">
      <Query useStatic query={SPEC_ITEM} variables={{ id: specItemId }}>
        {({ data, loading }) => {
          if (loading) {
            return <Cover specItem={specDesc.specItem} specDesc={specDesc} />;
          }

          const specItem = data.specItem;

          return (
            <React.Fragment>
              {specItem.specDescs.length === 0 && (
                <Text bold bg="white" p={6}>
                  {specItem.name}
                </Text>
              )}
              {specItem.specDescs.map((desc, index) => (
                <Cover key={index} specDesc={desc} specItem={specItem} p={6} />
              ))}
              <GradesTable specItem={specItem} py={7} px={6} mt={2} />
              <Comments specItemId={specItemId} py={7} px={6} mt={2} />
            </React.Fragment>
          );
        }}
      </Query>
    </Box>
  );
};

export default SpecDetail;
