import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { parse as parseQueryString } from "query-string";

import { useLocalStorage } from "lib/hooks";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import BuildIcon from "@material-ui/icons/Build";

import Flex from "components/base/Flex";
import UTMManagerModal from "components/admin/tools/UTMManagerModal";

import logo from "assets/logo.png";

import paths from "paths";

const styles = {
  grow: {
    flexGrow: 1
  },
  toolbar: {
    minHeight: "40px"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

const MenuAppBar = props => {
  const { classes, location } = props;

  const [enableTools, setEnableTools] = useLocalStorage("enableTools", false);
  const [toolModalOpen, setToolModalOpen] = useState(false);

  useEffect(() => {
    const { atools } = parseQueryString(props.location.search);
    if (atools === "on") {
      setEnableTools(true);
    } else if (atools === "off") {
      setEnableTools(false);
    }
  }, []);

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Menu"
          onClick={() => props.onClickMenu()}
          style={{ marginLeft: "-12px" }}
        >
          <MenuIcon />
        </IconButton>
        <Flex flexGrow="1">
          <Flex to={paths.main()}>
            <img alt="full-option.com" src={logo} width="100px" height="100%" />
          </Flex>
        </Flex>
        {enableTools && (
          <React.Fragment>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={() => setToolModalOpen(true)}
            >
              <BuildIcon />
            </IconButton>
            <UTMManagerModal
              location={location}
              open={toolModalOpen}
              onClose={() => setToolModalOpen(false)}
            />
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(MenuAppBar));
