import React from "react";
import styled from "styled-components";
import { color, border } from "styled-system";

const Wrapper = styled.div`
  margin-right: 4px;
  line-height: 1.52857;
  ${props => (props.showAs === "inline" ? "display: inline-block" : "")};
`;

const InputOverwrap = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 1px solid;
  margin-right: ${props => (props.showAs === "inline" ? 0 : 5)}px;
  text-align: center;
  font-weight: 600;
  font-size: ${props => props.size / 2 + 3}px;
  border-radius: ${props => (props.shape === "circle" ? "100%" : "2px")};
  ${color}
  ${border}
}
`;

const CheckDot = props => {
  const {
    theme,
    shape, // (none): rounded square, circle: circle
    size,
    showAs,
    style, // main div에 지정하는 스타일
    spanStyle, // span에 직접 지정하는 스타일. 마진등을 조정한다.
    number,
    bg,
    onClick
  } = props;

  return (
    <Wrapper
      style={style}
      showAs={showAs}
      onClick={e => {
        e.preventDefault();
        return onClick(e);
      }}
    >
      <InputOverwrap
        style={spanStyle}
        theme={theme}
        shape={shape}
        showAs={showAs}
        size={size}
        bg={bg}
        borderColor={bg ? bg : "silver"}
      >
        {number ? number : ""}
      </InputOverwrap>
    </Wrapper>
  );
};

CheckDot.defaultProps = {
  size: 18,
  showAs: "block",
  checked: false,
  onClick: () => {}
};

export default CheckDot;
