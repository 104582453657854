import React, { useState } from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import ModelBasicCompare from "components/compare/ModelBasicCompare";

const Compare = ({ model, ...rest }) => {
  const [trimToCompare, setTrimToCompare] = useState(false);
  const [baseModelTrim, setBaseModelTrim] = useState(null);

  const defaultTrim = model.grades[0].trims[0];
  defaultTrim.grade = model.grades[0];
  defaultTrim.model = model;

  return (
    <Box bg="white" {...rest}>
      <Text mb={4} fontSize="1.5rem" fontWeight="bold">
        비교하기
      </Text>
      <ModelBasicCompare
        trims={[baseModelTrim || defaultTrim, trimToCompare]}
        onChangeTrim={(newTrim, oldTrim) => {
          if (oldTrim) {
            if (
              oldTrim.id === defaultTrim.id ||
              (baseModelTrim && oldTrim.id === baseModelTrim.id)
            ) {
              return setBaseModelTrim(newTrim);
            }
          }
          setTrimToCompare(newTrim);
        }}
      />
      {trimToCompare && (
        <Flex mt={3} justifyContent="flex-end">
          <Button
            to={`/compare?trimIds=${defaultTrim.id}&trimIds=${trimToCompare.id}`}
          >
            비교하기
          </Button>
        </Flex>
      )}
      {!trimToCompare && (
        <Flex mt={3} justifyContent="flex-end">
          <Button
            bg="silver"
            onClick={() => window.alert("비교할 모델을 선택하세요.")}
          >
            비교하기
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Compare;
