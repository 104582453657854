import React, { Component } from "react";
import PropTypes from "prop-types";
import Countup from "react-countup";

export default class CountTo extends Component {
  static propTypes = { to: PropTypes.number };
  static defaultProps = { to: 0 };
  state = { from: 0, to: this.props.to };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.to !== prevState.to) {
      return {
        from: prevState.to, // The "old" target number
        to: nextProps.to // The "new" target number
      };
    }

    return null;
  }

  render() {
    return (
      <Countup start={this.state.from} end={this.state.to} {...this.props} />
    );
  }
}
