import React from "react";

import Box from "components/base/Box";

import MANUFACTURERS, { MANUFACTURERS_BY_ID } from "./manufacturers";

const sizes = {
  50: "50px",
  80: "80px",
  120: "120px"
};

const ManufacturerLogo = ({ name, id, size = 50, white, ...rest }) => {
  const manufacturer = MANUFACTURERS[name] || MANUFACTURERS_BY_ID[id];
  if (!manufacturer) return null;

  const logo = white
    ? manufacturer.logoWhite || manufacturer.logo
    : manufacturer.logo;
  if (!logo) return null;

  return (
    <Box width={sizes[size]} display="inline-block" {...rest}>
      <img alt={name} src={logo} width="100%" />
    </Box>
  );
};

export default ManufacturerLogo;
