import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const drawerHeight = 500;
const styles = theme => ({
  root: {},
  list: {},
  fullList: {
    width: "auto"
  },
  paper: {
    boxShadow: "0 -2px 20px 0 rgba(0, 0, 0, 0.5)"
  },
  drawerOpen: {
    height: drawerHeight,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    height: 110
  }
});

class BottomDrawer extends React.Component {
  static defaultProps = {
    isOpen: false
  };

  render() {
    const { classes, isOpen, onClose, children } = this.props;

    return (
      <div className={classes.root}>
        <Drawer
          anchor="bottom"
          variant="permanent"
          open={isOpen}
          onClose={onClose}
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen
          })}
          classes={{
            paper: classNames({
              [classes.paper]: true,
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen
            })
          }}
        >
          <div tabIndex={0} role="button" onKeyDown={onClose}>
            {children}
          </div>
        </Drawer>
      </div>
    );
  }
}

BottomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BottomDrawer);
