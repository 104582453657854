import gql from "graphql-tag";

export const CREATE_OR_FIND_COMPARISON = gql`
  mutation($trimIds: [ID!]!, $specGroupIds: [ID!]!) {
    createComparison(
      input: { trimIds: $trimIds, specGroupIds: $specGroupIds }
    ) {
      comparison {
        id
      }
      errors {
        messages
      }
    }
  }
`;
