import React from "react";
import { parse, format } from "fecha";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";

import paths from "paths";

const ListItem = ({ post }) => {
  return (
    <Box bg="white" borderBottom="1px solid" borderColor="mercury" p={4}>
      <Box to={paths.posts.detail(post.id)}>
        <Text fontSize="1.1rem">{post.title}</Text>
        <Flex flexDirection="row-reverse" justifyContent="space-between" mt={1}>
          <Box fontSize="0.8rem" color="tin">
            <Text inlineBlock color="charcoal">
              {post.user.name}
            </Text>
            <Text inlineBlock px={2}>
              |
            </Text>
            <Text inlineBlock>
              {format(parse(post.createdAt, "YYYY-MM-DDTHH:mm:ssZ"), "MM/DD")}
            </Text>
          </Box>
          <Text bg="skyBlue" px={2} py="2px" fontSize="0.8rem" color="white">
            {post.comparisonLabel.join(" vs ")}
          </Text>
        </Flex>
        <Flex color="tin" justifyContent="flex-end" mt={2}>
          <Icon className="far fa-comment-dots" mr={1} />
          <Text>{post.postComments.totalCount}</Text>
        </Flex>
      </Box>
    </Box>
  );
};
export default ListItem;
