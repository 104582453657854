import { stringify } from "query-string";

const paths = {
  domain: "https://www.full-option.com",
  main() {
    return "/";
  },
  auth: {
    signup() {
      return "/signup";
    },
    login() {
      return "/login";
    }
  },
  compare() {
    return "/compare";
  },
  trimCompare() {
    return "/trim-compare";
  },
  manufacturers: {
    models(manufacturerName) {
      return `/manufacturers/${manufacturerName}/models`;
    }
  },
  models: {
    index(modelId) {
      return `/models/${modelId}`;
    }
  },
  trims(modelId, trimId) {
    return `/models/${modelId}/trims/${trimId}`;
  },
  posts: {
    index() {
      return "/posts/qna";
    },
    write() {
      return `/posts/write`;
    },
    detail(id) {
      return `/posts/${id}`;
    }
  }
};

export const makeCompareUrl = comparison => {
  const c1 = comparison.combination1;
  const c2 = comparison.combination2;

  const trimIds = [c1.trim.id, c2.trim.id];
  const groupIds = c1.specGroups
    .map(g => g.id)
    .concat(c2.specGroups.map(g => g.id));

  return paths.compare() + "?" + stringify({ trimIds, groupIds });
};

export default paths;
