import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import CurrencyFormat from "components/base/CurrencyFormat";

const drawerStyles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 400
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  subheader: {
    backgroundColor: theme.palette.grey[200]
  }
});

class TrimSelectDrawer extends React.Component {
  static defaultProps = {
    model: null
  };

  render() {
    const { classes, model, onClickTrim } = this.props;
    const { isOpen, onClose } = this.props;

    return (
      <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
        <List className={classes.root} subheader={<li />}>
          {model &&
            model.grades.map(grade => (
              <li key={`section-${grade.name}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader className={classes.subheader}>
                    {grade.name}
                  </ListSubheader>
                  {grade.trims.map(trim => (
                    <ListItem
                      key={`specItem-${grade.id}-${trim.id}`}
                      onClick={() => {
                        trim.model = model;
                        trim.grade = grade;
                        onClickTrim(trim);
                      }}
                    >
                      <ListItemText>{trim.name}</ListItemText>
                      <CurrencyFormat amount={trim.price} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
        </List>
      </Drawer>
    );
  }
}

TrimSelectDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(drawerStyles)(TrimSelectDrawer);
