import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Query as ApolloQuery } from "react-apollo";
import ReactLoading from "react-loading";

import { normalClient, staticClient } from "graphql/apolloClient";

import Flex from "components/base/Flex";

const propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  skipDefaultLoading: PropTypes.bool,
  skipDefaultError: PropTypes.bool,
  ignoreNotSubscribedError: PropTypes.bool,
  children: PropTypes.func.isRequired
};

const defaultProps = {
  skipDefaultLoading: false,
  skipDefaultError: false,
  ignoreNotSubscribedError: false
};

const loading = result => result.loading && result.networkStatus !== 3;
const fetchingMore = result => result.loading && result.networkStatus === 3;
const refetching = result => result.loading && result.networkStatus === 4;

const Query = ({
  query,
  variables,
  skipDefaultLoading,
  skipDefaultError,
  children,
  useStatic,
  ...rest
}) => {
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowLoading(true), 1000);
  }, []);

  return (
    <ApolloQuery
      query={query}
      variables={variables}
      client={useStatic ? staticClient : normalClient}
      {...rest}
    >
      {queryResult => {
        if (!skipDefaultLoading && loading(queryResult)) {
          if (!showLoading) return null;

          return (
            <Flex
              justifyContent="center"
              alignItems="center"
              width={1}
              mt="200px"
            >
              <ReactLoading type="bars" color="#347EDD" />
            </Flex>
          );
        }

        if (!skipDefaultError && queryResult.error) {
          return <div>Error</div>;
        }

        return children({
          ...queryResult,
          loading: loading(queryResult),
          fetchingMore: fetchingMore(queryResult),
          refetching: refetching(queryResult)
        });
      }}
    </ApolloQuery>
  );
};

Query.propTypes = propTypes;
Query.defaultProps = defaultProps;

export default Query;
