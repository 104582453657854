import React from "react";
import styled from "styled-components";
import theme from "theme";

import {
  color,
  space,
  typography,
  layout,
  flexbox,
  border,
  position
} from "styled-system";

const colors = {
  default: {
    bg: "#497bde",
    color: "white"
  },
  soft: {
    bg: "white",
    color: theme.colors.charcoal,
    border: theme.colors.gray58
  },
  softly: {
    bg: "white",
    color: theme.colors.gray58,
    border: theme.colors.mercury
  },
  disabled: {
    bg: "#eee",
    color: "#bbb"
  },
  link: {
    bg: "transparent",
    color: "inherit",
    padding: "2px 4px"
  }
};

const Default = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props =>
    colors[props.paint ? props.paint : "default"].padding || "6px 16px"};
  font-size: 0.9rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  text-align: center;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 1px solid ${props =>
    colors[props.paint ? props.paint : "default"].border || "transparent"};
  color: ${props =>
    props.disabled
      ? colors.disabled.color
      : colors[props.paint ? props.paint : "default"].color};
  background-color: ${props =>
    props.disabled
      ? colors.disabled.bg
      : colors[props.paint ? props.paint : "default"].bg};
  ${color}${space}${typography}${layout}${flexbox}${border}${position}
`;

const Button = ({ to, ...props }) => {
  if (to) {
    return <Default as="a" href={to} {...props} />;
  }

  return <Default {...props} />;
};

export default Button;
