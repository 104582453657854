import set from "lodash/set";

export function loadMoreTrim(trimId, fetchMore, callback) {
  return fetchMore({
    variables: {
      ids: [trimId],
      isFetchMore: true
    },
    updateQuery(previousResult, { fetchMoreResult }) {
      return set(fetchMoreResult, "trims", [
        ...previousResult.trims,
        ...fetchMoreResult.trims
      ]);
    }
  })
    .then(() => callback())
    .catch(e => {
      if (!/^ObservableQuery with this id doesn't exist:/.test(e.message)) {
        throw e;
      }
    });
}

export function extractEdgeNodes(connection) {
  if (!connection || !connection.edges) {
    return [];
  }

  return connection.edges.map(({ node }) => node);
}
