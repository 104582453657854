import React from "react";

import Query from "graphql/Query";

import Box from "components/base/Box";
import Pagination, { DEFAULT_PAGE_SIZE } from "components/common/Pagination";
import ListItem from "./ListItem";

import { extractEdgeNodes } from "graphql/utils";

import { POSTS } from "../queries";

const QnAList = () => (
  <Query query={POSTS} variables={{ first: DEFAULT_PAGE_SIZE }}>
    {({ data, fetchMore }) => {
      const posts = extractEdgeNodes(data.posts);

      return (
        <React.Fragment>
          {posts.map(post => (
            <ListItem key={post.id} post={post} />
          ))}
          <Pagination
            data={data}
            dataPath="posts"
            fetchMore={fetchMore}
            mt={2}
          />
        </React.Fragment>
      );
    }}
  </Query>
);

export default QnAList;
