import React from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import NumberedCheck from "components/base/NumberedCheck";
import CurrencyFormat from "components/base/CurrencyFormat";

const SpecGroup = ({ specGroup, theme = 1, ...rest }) => {
  return (
    <Box fontSize="0.7rem" color="stone" textAlign="left" mb={2} {...rest}>
      <NumberedCheck
        displayOnly
        label={specGroup.name}
        number={specGroup.sortWeight}
        theme={theme}
        shape="circle"
        spanStyle={{ marginTop: "2px" }}
        size={12}
        checked={true}
      />
      <Flex justifyContent="flex-end" mb={1}>
        <CurrencyFormat amount={specGroup.price} />
      </Flex>
      <Box fontSize="0.5rem" color="tin" textAlign="justify">
        {specGroup.specItems.map(specItem => specItem.name).join(", ")}
      </Box>
    </Box>
  );
};

export default SpecGroup;
