import React, { useState } from "react";
import { stringify } from "query-string";
import { withRouter } from "react-router-dom";
// TODO: 나중에 가벼운 로딩 인디케이터로 바꾸자 19/11/3
import ReactLoading from "react-loading";

import Query from "graphql/Query";
import paths from "paths";

import withUrlQuery from "components/route/withUrlQuery";

import Flex from "components/base/Flex";
import ModelBasicCompare from "components/compare/ModelBasicCompare";
import Header from "components/base/Header";
import ShareDrawer from "components/common/ShareDrawer";

import SpecGroupDrawer from "./components/SpecGroupDrawer";
import CompareTable from "./components/CompareTable";

import { DrawerBackDrop, FabButton } from "./styles";
import { TRIMS_AND_CATEGORIES_QUERY } from "./queries";
import { CompareMeta } from "components/common/Meta";

const ModelCompare = ({
  trimIds: paramTrimIds = [],
  groupIds = [],
  trimSize,
  match,
  history,
  updateUrlQueries
}) => {
  const [isSpecGroupDrawerOpen, setIsSpecGroupDrawerOpen] = useState(
    groupIds.lenght > 0
  );

  const trimIds =
    paramTrimIds.length > 0 ? paramTrimIds : [match.params.trimIds];

  const handleChangeTrim = (newTrim, oldTrim) => {
    if (trimSize === 1) {
      history.replace(paths.trims(newTrim.model.uniqName, newTrim.id));
      return;
    }

    if (paramTrimIds.includes(newTrim.id)) return;

    // props의 trimIds를 복사 - arrayFormat이 'none'이라 스트링으로 넘어옴(param이 하나인 경우)
    const newTrimIds = [paramTrimIds].flat();

    // 찾은 트림을 새로운 트림으로 replace
    if (oldTrim) {
      newTrimIds[newTrimIds.indexOf(oldTrim.id)] = newTrim.id;
    } else {
      newTrimIds.push(newTrim.id);
    }

    history.push({
      pathname: paths.compare(),
      search: stringify(
        {
          trimIds: newTrimIds
        },
        { arrayFormat: "none" }
      )
    });
  };

  const handleClickItem = ({ group, checked }) => {
    const newGroupIds = [groupIds].flat();

    if (checked) {
      updateUrlQueries({ groupIds: newGroupIds.concat([group.id]) });
    } else {
      updateUrlQueries({ groupIds: newGroupIds.filter(id => id !== group.id) });
    }
  };

  return (
    <div>
      {"" /* TODO: Portal을 사용해서 Drawer로 넘기자 */}
      {isSpecGroupDrawerOpen && (
        <DrawerBackDrop onClick={() => setIsSpecGroupDrawerOpen(false)} />
      )}
      {"" /* TODO: useStatic을 사용하고 각 트림별로 쿼리를 날리자 */}
      <Query query={TRIMS_AND_CATEGORIES_QUERY} variables={{ ids: trimIds }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Flex
                justifyContent="center"
                alignItems="center"
                width={1}
                mt="200px"
              >
                <ReactLoading type="bars" color="#347EDD" />
              </Flex>
            );
          }
          if (error) return <div>Error</div>;

          const specCategories = data.specCategories;
          const trims = data.trims;

          return (
            <React.Fragment>
              <CompareMeta trims={trims} />
              <Header
                backEnabled
                align="left"
                fontWeight="bold"
                bg="concrete"
                defaultBackPath={paths.models.index(trims[0].model.uniqName)}
                right={<ShareDrawer />}
              >
                {trimSize ? "뒤로가기" : "비교하기"}
              </Header>
              <ModelBasicCompare
                p={3}
                trims={trims}
                trimSize={trimSize}
                onChangeTrim={handleChangeTrim}
              />
              <CompareTable
                specCategories={specCategories}
                trims={trims}
                trimSize={trimSize}
                onSpecItemClick={handleClickItem}
              />
              <SpecGroupDrawer
                trims={trims}
                isOpen={isSpecGroupDrawerOpen}
                onToggleDrawer={setIsSpecGroupDrawerOpen}
              />
              {!isSpecGroupDrawerOpen && (
                <FabButton onClick={() => setIsSpecGroupDrawerOpen(true)} />
              )}
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

const urlQueryWhiteList = ["trimIds", "groupIds"];

export default withRouter(withUrlQuery(urlQueryWhiteList)(ModelCompare));
