import React from "react";

import Text from "components/base/Text";

const Tab = ({ label, value, selectedValue, onClick }) => {
  const defaltProps =
    value === selectedValue
      ? {
          borderBottom: "2px solid",
          borderColor: "black"
        }
      : {
          color: "tin"
        };

  return (
    <Text
      inlineBlock
      bold
      fontSize="0.9rem"
      pb="2px"
      ml={2}
      onClick={() => onClick(value)}
      {...defaltProps}
    >
      {label}
    </Text>
  );
};

const Tabs = ({ tabItems, selectedValue, onClick }) => {
  return (
    <React.Fragment>
      {tabItems.map((item, index) => (
        <Tab
          key={item.label ? item.value : index}
          label={item.label ? item.label : item}
          value={item.label ? item.value : index}
          selectedValue={selectedValue}
          onClick={onClick}
        />
      ))}
    </React.Fragment>
  );
};

export default Tabs;
