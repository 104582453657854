import React from "react";
import { withRouter } from "react-router";
import { parse as parseQueryString, stringify } from "query-string";
import pick from "lodash/pick";
import trimMapping from "lib/data/trimMapping";
import specGroupMapping from "lib/data/specGroupMapping";

const parseOptions = { arrayFormat: "none" };

const convertToShort = obj => {
  if (obj.trimIds) obj.t = obj.trimIds;
  if (obj.groupIds) obj.g = obj.groupIds;
  obj.trimIds = undefined;
  obj.groupIds = undefined;
};

const convertToNormal = obj => {
  if (obj.t) obj.trimIds = obj.t;
  if (obj.g) obj.groupIds = obj.g;
  obj.t = undefined;
  obj.g = undefined;
};

const withUrlQuery = whiteList => WrappedComponent => {
  class WithUrlQuery extends React.Component {
    updateUrlQueries = (params = {}) => {
      convertToShort(params);

      const queries = {
        ...parseQueryString(this.props.location.search, parseOptions),
        ...params
      };

      this.props.history.replace({
        search: stringify(
          {
            ...queries
          },
          parseOptions
        )
      });
    };

    render() {
      const parsed = parseQueryString(this.props.location.search, parseOptions);

      if (parsed.trimIds || parsed.groupIds) {
        if (parsed.trimIds) {
          parsed.trimIds = parsed.trimIds.map(t =>
            trimMapping[t] ? trimMapping[t] : t
          );
        }

        if (parsed.groupIds) {
          parsed.groupIds = parsed.groupIds.map(t =>
            specGroupMapping[t] ? specGroupMapping[t] : t
          );
        }

        convertToShort(parsed);

        this.props.history.replace({
          search: stringify(
            {
              ...parsed
            },
            parseOptions
          )
        });
      }

      convertToNormal(parsed);
      const queryProps = pick(parsed, whiteList);

      return (
        <WrappedComponent
          {...queryProps}
          {...this.props}
          updateUrlQueries={this.updateUrlQueries}
        />
      );
    }
  }

  withUrlQuery.displayName = `WithUrlQuery(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`;

  return withRouter(WithUrlQuery);
};

export default withUrlQuery;
