import gql from "graphql-tag";

import { fragments as specItemComment } from "graphql/queries/specItemComment.graphql";

export const SPEC_ITEM_COMMENTS = gql`
  query SpecItem($id: ID!) {
    specItem(id: $id) {
      totalCommentsCount
      comments {
        ...SpecItemCommentFields
        children {
          ...SpecItemCommentFields
        }
      }
    }
  }
  ${specItemComment.SpecItemComment}
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($specItemId: ID!, $content: String!, $parentId: ID) {
    createSpecItemComment(
      input: { specItemId: $specItemId, content: $content, parentId: $parentId }
    ) {
      specItemComment {
        ...SpecItemCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${specItemComment.SpecItemComment}
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $specItemId: ID!, $content: String!) {
    updateSpecItemComment(
      input: { id: $id, specItemId: $specItemId, content: $content }
    ) {
      specItemComment {
        ...SpecItemCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${specItemComment.SpecItemComment}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!, $specItemId: ID!) {
    deleteSpecItemComment(input: { id: $id, specItemId: $specItemId }) {
      specItemComment {
        ...SpecItemCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${specItemComment.SpecItemComment}
`;
