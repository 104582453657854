import React, { useState } from "react";
import styled from "styled-components";
import intersection from "lodash/intersection";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import NumberedCheck from "components/base/NumberedCheck";
import CheckDot from "components/base/CheckDot";
import withUrlQuery from "components/route/withUrlQuery";

import { size } from "theme";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const BORDER_COLOR = "concrete";
const _Row = props => (
  <Flex borderBottom="1px solid" borderColor={BORDER_COLOR} {...props} />
);
const Row = styled(_Row)``;
const _Cell = props => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    borderLeft="1px solid"
    borderColor={BORDER_COLOR}
    p={2}
    fontSize="0.8em"
    {...props}
  />
);
const Cell = styled(_Cell)`
  &:first-child {
    border: 0;
  }
`;

const TrimCell = props => <Cell width="100px" {...props} />;

const SpecItemRow = ({
  specItem,
  trims,
  selectedGrouIds,
  filter: { commonFilter, installedFilter },
  onGroupCheckChanged
}) => {
  const marks = trims.map(trim => {
    const basic = specItem.trimIds.includes(trim.id);
    const groupIds = intersection(specItem.specGroupIds, trim.specGroupIds);

    return {
      // 기본 장착, 옵션, 옵션(장착됨), 장착불가
      id: trim.id,
      basic: basic,
      trimSpecGroupIds: trim.specGroupIds,
      groupIds,
      selected: intersection(selectedGrouIds, trim.specGroupIds),
      notAvailable: !basic && groupIds.length === 0
    };
  });

  // 트림 모두의 기본 or 선택 어느것도 해당되지 않는 경우
  if (marks.every(m => !m.basic) && marks.every(m => m.groupIds.length === 0)) {
    return null;
  }

  // 공통 기본 옵션 보이기 X && 모든 트림의 기본 옵션인 경우
  if (!commonFilter && marks.every(m => m.basic)) {
    return null;
  }

  // 장착된 옵션만 보기 && 기본도 없고 그룹은 있지만 선택되지 않은 경우
  if (
    installedFilter &&
    marks.every(m => !m.basic) &&
    marks.every(m => m.selected.length === 0)
  ) {
    return null;
  }

  const groupIndex = (trimSpecGroupIds, groupId) =>
    trimSpecGroupIds.indexOf(groupId) + 1;

  return (
    <Row key={specItem.id}>
      <Cell color="charcoal" textAlign="right" alignItems="flex-end" flex={1}>
        {specItem.name}
      </Cell>
      {marks.map((mark, index) => (
        <TrimCell key={mark.id} flexDirection="row">
          {mark.basic && (
            <CheckDot showAs="inline" shape="circle" bg="gray58" />
          )}
          {mark.groupIds.length > 0 &&
            mark.groupIds.map(groupId => (
              <NumberedCheck
                key={groupId}
                showAs="inline"
                number={groupIndex(mark.trimSpecGroupIds, groupId)}
                theme={index + 1}
                shape="circle"
                checked={mark.selected.includes(groupId)}
                onChange={checked => onGroupCheckChanged(groupId, checked)}
              />
            ))}
        </TrimCell>
      ))}
    </Row>
  );
};

const CompareTable = ({ model, trims, groupIds, updateUrlQueries }) => {
  const modelCategories = model.modelCategories;
  const [commonFilter, setCommonFilter] = useState(false);
  const [installedFilter, setInstalledFilter] = useState(false);

  const handleGroupCheckChanged = (groupId, checked) => {
    if (checked) {
      updateUrlQueries({ groupIds: groupIds.concat([groupId]) });
    } else {
      updateUrlQueries({ groupIds: groupIds.filter(id => id !== groupId) });
    }
  };

  return (
    <Box>
      <Row>
        <TrimCell flex={1}>
          {model.representImage && (
            <img
              alt={model.name}
              src={model.representImage.small}
              width="80%"
              style={{ maxWidth: size.modelImageWidth }}
            />
          )}
          <Text fontSize="0.8rem" fontWeight="bold">
            {model.name}
          </Text>
        </TrimCell>
        {trims.map(trim => (
          <TrimCell key={trim.id}>
            <Text fontSize="0.8rem" color="tin">
              {trim.grade.name}
            </Text>
            <Flex alignItems="center" pl={2}>
              {trim.name}
              <KeyboardArrowDown />
            </Flex>
          </TrimCell>
        ))}
      </Row>
      <Row>
        <Cell alignItems="flex-start">
          <NumberedCheck
            mb={1}
            label="공통 기본 옵션 보이기"
            theme={1}
            onChange={checked => setCommonFilter(checked)}
            checked={commonFilter}
          />
          <NumberedCheck
            label="장착된 옵션만 보기"
            theme={1}
            onChange={checked => setInstalledFilter(checked)}
            checked={installedFilter}
          />
        </Cell>
      </Row>
      {modelCategories.map(modelCategory => (
        <React.Fragment key={modelCategory.id}>
          <Row bg="#F5F7FA">
            <Cell fontWeight="bold">{modelCategory.name}</Cell>
          </Row>
          {modelCategory.specItems.map(specItem => (
            <SpecItemRow
              key={specItem.id}
              specItem={specItem}
              trims={trims}
              selectedGrouIds={groupIds}
              filter={{ commonFilter, installedFilter }}
              onGroupCheckChanged={handleGroupCheckChanged}
            />
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
};

CompareTable.defaultProps = {
  groupIds: []
};

export default withUrlQuery(["groupIds"])(CompareTable);
