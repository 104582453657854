import React, { Component } from "react";
import { Router, Route } from "react-router-dom";

import { ApolloProvider } from "react-apollo";
import { withTracker } from "lib/hocs/withTracker";

import { ThemeProvider } from "styled-components";
import theme from "./theme";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import MainLayout from "components/layouts/MainLayout";
import SingleLayout from "./components/layouts/SingleLayout";

import ModelCompareView from "views/ModelCompare";
import SingleTrimCompareView from "views/ModelCompare/SingleTrim";
import TrimCompareView from "views/TrimCompare";
import MainView from "views/Main";
import Models from "views/Models";
import Manufacturers from "views/Manufacturers";
import PostsView from "views/Posts/List";
import PostView from "views/Posts/Detail";
import WritePostView from "views/Posts/WritePost";
import SignupView from "views/Auth/Signup";
import LoginView from "views/Auth/Login";
import LoginWithKakaoView from "views/Auth/LoginWithKakao";
import Specs from "views/Specs";

import Meta from "components/common/Meta";

import { normalClient } from "graphql/apolloClient";
import history from "browserHistory";
import { TemporaryInfoProvider } from "lib/contexts/TemporaryInfoContext";

const muiTheme = createMuiTheme({
  palette: theme.colors
});

// styled-components도 theme을 사용하고, MUI도 theme을 사용하려면
// 이렇게 ThemeProvider를 모두 둬야 한다.
class App extends Component {
  render() {
    return (
      <ApolloProvider client={normalClient}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={muiTheme}>
              <TemporaryInfoProvider>
                <Route component={withTracker(AppMain, {})} />
              </TemporaryInfoProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </Router>
      </ApolloProvider>
    );
  }
}

const AppRoute = ({
  component: Component,
  layout: Layout = MainLayout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const AppMain = () => (
  <React.Fragment>
    <Meta />
    <AppRoute exact path="/" component={MainView} />
    <AppRoute path="/compare" component={ModelCompareView} />
    <AppRoute
      exact
      path="/models/:modelId/trims/:trimIds"
      component={SingleTrimCompareView}
    />
    <AppRoute path="/trim-compare" component={TrimCompareView} />
    <AppRoute path="/main" component={MainView} />
    <AppRoute exact path="/models/:id" component={Models} />
    <AppRoute exact path="/models/:id/specs/:specItemId" component={Specs} />
    <AppRoute path="/manufacturers/:name" component={Manufacturers} />
    <AppRoute path="/signup" component={SignupView} />
    <AppRoute exact path="/login/kakao" component={LoginWithKakaoView} />
    <AppRoute exact path="/login" component={LoginView} />
    <AppRoute
      path="/posts/write"
      component={WritePostView}
      layout={SingleLayout}
    />
    <AppRoute exact path="/posts/qna" component={PostsView} />
    <AppRoute exact path="/posts/:id([0-9]*)" component={PostView} />
    <AppRoute exact path="/specs/:specItemId" component={Specs} />
  </React.Fragment>
);

export default App;
