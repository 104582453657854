import React, { useContext, useEffect, useState } from "react";
import { Mutation } from "react-apollo";

import { TemporaryInfoContext } from "lib/contexts/TemporaryInfoContext";
import { isLoggedin, userInfo } from "lib/helper/auth";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import TextArea from "components/base/TextArea";

import { CREATE_COMMENT, UPDATE_COMMENT } from "./queries";

const CommentForm = ({ comment, onSave, open = false, onClose, children }) => {
  const [opened, setOpened] = useState(open);
  const [tempExistBeforeMount, setTempExistBeforeMount] = useState(false);
  const { info, setInfo } = useContext(TemporaryInfoContext);
  const attributes = info.writingComment;

  useEffect(() => {
    if (
      comment.id !== attributes.id ||
      comment.postId !== attributes.postId ||
      comment.parentId !== attributes.parentId
    ) {
      setOpened(false);
      onClose && onClose();
    }
  }, [attributes]);

  const handleOpen = () => {
    if (
      comment.id !== attributes.id ||
      comment.postId !== attributes.postId ||
      comment.parentId !== attributes.parentId
    ) {
      setInfo({ writingComment: comment });
    } else if (attributes.content) {
      setTempExistBeforeMount(true);
    }

    setOpened(true);
  };

  const handleChange = (field, event) => {
    const attribute = {};
    attribute[field] = event.target.value;
    setInfo({ writingComment: { ...attributes, ...attribute } });
  };

  const handleSave = mutate => {
    console.log(attributes);
    return mutate({
      variables: attributes
    })
      .then(({ data: { createPostComment, updatePostComment } }) => {
        const payload = createPostComment || updatePostComment;

        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        onSave();

        setInfo({ writingComment: {} });
        setOpened(false);
      })
      .catch(e => {
        console.log(e);
      });
  };

  if (!isLoggedin()) {
    return (
      <Flex
        alignItems="center"
        border="1px solid"
        borderColor="mercury"
        p={2}
        flex={1}
        onClick={() => alert("로그인이 필요합니다. 로그인 하시겠습니까?")}
      >
        <Text flex={1} color="tin">
          답글을 입력하세요.
        </Text>
        <Button paint="soft" padding="2px 6px" width="50px">
          등록
        </Button>
      </Flex>
    );
  }

  if (!opened) {
    return children ? children({ open: handleOpen }) : null;
  }

  return (
    <Box flex={1}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text bold mb={2}>
          {userInfo().name}
        </Text>
        {tempExistBeforeMount && (
          <Text color="silver" fontSize="0.5rem">
            임시저장된 답글을 불러왔습니다.
          </Text>
        )}
      </Flex>
      <Box bg="white" border="1px solid" borderColor="mercury">
        <TextArea
          minHeight="100px"
          placeholder="답글을 입력해주세요.&#13;&#10;부드러운 표현이 서로를 기분좋게 합니다."
          value={attributes.content || ""}
          onChange={e => handleChange("content", e)}
        />
      </Box>
      <Flex mt={2} justifyContent="flex-end">
        <Mutation mutation={attributes.id ? UPDATE_COMMENT : CREATE_COMMENT}>
          {mutate => {
            return (
              <Button
                paint="soft"
                padding="2px 6px"
                onClick={() => handleSave(mutate)}
              >
                등록
              </Button>
            );
          }}
        </Mutation>
      </Flex>
    </Box>
  );
};

export default CommentForm;
