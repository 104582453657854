import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "components/base/Icon";

import Text from "components/base/Text";
import ManufacturerLogo from "components/manufacturers/ManufacturerLogo";
import withKakao from "lib/hocs/withKakao";

import MANUFACTURERS from "components/manufacturers/manufacturers";
import paths from "paths";
import { isLoggedin, logout, userInfo } from "lib/helper/auth";

import { useLocalStorage } from "lib/hooks";

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

const startKakaoChat = () => {
  window.Kakao.Channel.chat({
    channelPublicId: "_Sxizxdxb" // 카카오톡 채널 홈 URL에 명시된 id로 설정합니다.
  });
};

const MenuDrawer = ({ classes, isOpen, onClose, isKakaoLoaded }) => {
  const [enableTools, _setEnableTools] = useLocalStorage("enableTools", false); // eslint-disable-line no-unused-vars

  const sideList = (
    <div className={classes.list}>
      <ListItem>
        <ListItemText primary="&nbsp;" />
      </ListItem>
      <Divider />
      <ListItem>
        <Text to="/" fontWeight="bold">
          풀옵션 홈으로
        </Text>
      </ListItem>
      <Divider />
      <ListItem>
        <Text fontWeight="bold">제조사별 보기</Text>
      </ListItem>
      <List>
        {/* TODO: object to array 적용 */}
        {Object.entries(MANUFACTURERS)
          .filter(m => m[1].show)
          .map(manufacturerArr => (
            <ListItem
              button
              key={manufacturerArr[1].id}
              component={Link}
              to={paths.manufacturers.models(manufacturerArr[0])}
            >
              <ListItemIcon>
                <ManufacturerLogo name={manufacturerArr[0]} ml="-10px" />
              </ListItemIcon>
              <ListItemText primary={manufacturerArr[0]} />
            </ListItem>
          ))}
      </List>
      <Divider />
      {enableTools && (
        <React.Fragment>
          <List>
            {isLoggedin() ? (
              <ListItem onClick={() => logout()}>
                <ListItemIcon>
                  <Icon className="fa fa-user" />
                </ListItemIcon>
                <ListItemText primary={`${userInfo().name}님 로그아웃`} />
              </ListItem>
            ) : (
              <ListItem button component={Link} to={paths.auth.login()}>
                <ListItemIcon>
                  <Icon className="fa fa-user" />
                </ListItemIcon>
                <ListItemText primary="로그인" />
              </ListItem>
            )}
          </List>
          <Divider />
          <ListItem>
            <Text width="56px" />
            <Text to={paths.posts.index()}>묻고 답하기</Text>
          </ListItem>
          <Divider />
        </React.Fragment>
      )}
      {isKakaoLoaded && (
        <List>
          <ListItem onClick={() => startKakaoChat()}>
            <ListItemIcon>
              <Icon.Image name="kakaoTalk" size={34} />
            </ListItemIcon>
            <ListItemText primary="의견 보내기" />
          </ListItem>
          <ListItem>
            <Text color="charcoal" fontSize="0.8rem">
              카카오톡을 이용해 자유롭게 의견 및 오류 사항을 보내주세요. <br />
              혹시 차량을 고민중이시라면 선택에 관한 문의도 괜찮습니다. 대가
              없이 도움 드리고 의견을 듣고 싶습니다~
            </Text>
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={onClose}>
        <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
          {sideList}
        </div>
      </Drawer>
    </div>
  );
};

MenuDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withKakao(MenuDrawer));
