import React from "react";
import get from "lodash/get";

import Flex from "components/base/Flex";
import Icon from "components/base/Icon";

export const DEFAULT_PAGE_SIZE = 10;

const Pagination = ({
  data,
  dataPath,
  fetchMore,
  pageSize = DEFAULT_PAGE_SIZE,
  ...rest
}) => {
  const pageInfo = get(data, dataPath).pageInfo;

  const handlePagination = direction => {
    const variables =
      direction === "next"
        ? {
            after: pageInfo.endCursor,
            first: pageSize,
            last: null
          }
        : {
            before: pageInfo.startCursor,
            last: pageSize,
            first: null
          };

    return fetchMore({
      variables: {
        ...variables,
        isFetchMore: true
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult[dataPath].edges.length === 0)
          return prev;

        return fetchMoreResult;
      }
    });
  };

  return (
    <Flex center my={2} {...rest}>
      <Flex
        lineHeight="100%"
        color={pageInfo.hasPreviousPage ? "stone" : "silver"}
        py={2}
        px={5}
        onClick={() => pageInfo.hasPreviousPage && handlePagination("previous")}
      >
        <Icon className="fa fa-caret-left" />
        이전
      </Flex>
      <Flex
        lineHeight="100%"
        color={pageInfo.hasNextPage ? "stone" : "silver"}
        py={2}
        px={5}
        onClick={() => pageInfo.hasNextPage && handlePagination("next")}
      >
        다음
        <Icon className="fa fa-caret-right" ml={3} />
      </Flex>
    </Flex>
  );
};

export default Pagination;
