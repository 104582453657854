import React, { useState, useRef } from "react";
import Query from "graphql/Query";

import { extractEdgeNodes } from "graphql/utils";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";

import Comment from "./Comment";
import CommentForm from "./CommentForm";
import FormMockButton from "./FormMockButton";

import { POST_COMMENTS } from "./queries";

const Thread = ({ post, comment, onSave }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);

  const lastCommentRef = useRef(null);

  let openReplyFuncInForm = null;

  const handleOpenReply = () => {
    setShowReplyForm(true);
    /*
     * renderProps 패턴으로 구현된 CommentForm의 state를 컨트롤 하기 위해
     * 빈 children을 렌더링 하고 open 메서드를 가져와 Thread 컴포넌트에 저장한다.
     * 이후에 답글 버튼이 눌리면 해당 메서드를 실행해 CommentForm을 연다.
     */
    openReplyFuncInForm && openReplyFuncInForm();

    lastCommentRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <React.Fragment>
      <Comment
        comment={comment}
        postId={post.id}
        onClickReply={handleOpenReply}
        onSave={onSave}
      />
      {comment.children.map(child => (
        <Comment
          key={child.id}
          comment={child}
          postId={post.id}
          onClickReply={handleOpenReply}
          onSave={onSave}
        />
      ))}
      <div ref={lastCommentRef} />
      <Flex
        bg="concrete"
        p={4}
        borderBottom="1px solid"
        borderColor="mercury"
        style={{ display: showReplyForm ? "flex" : "none" }}
      >
        <Text width="20px" color="tin">
          ㄴ
        </Text>
        <CommentForm
          comment={{
            postId: post.id,
            parentId: comment.id
          }}
          onSave={onSave}
          onClose={() => setShowReplyForm(false)}
        >
          {({ open }) => {
            openReplyFuncInForm = open;

            return null;
          }}
        </CommentForm>
      </Flex>
    </React.Fragment>
  );
};

const Comments = ({ post }) => {
  return (
    <Query query={POST_COMMENTS} variables={{ postId: post.id }}>
      {({ data, fetchMore }) => {
        const comments = extractEdgeNodes(data.postComments);

        const handleAfterSave = () => {
          return fetchMore({
            variables: {
              postId: post.id,
              isFetchMore: true
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            }
          });
        };

        return (
          <React.Fragment>
            <Box mb={5}>
              <Text
                py={2}
                px={4}
                borderBottom="1px solid"
                borderColor="mercury"
              >
                댓글 ({data.postComments.totalCount})
              </Text>
              {comments.map(comment => (
                <Thread
                  key={comment.id}
                  post={post}
                  comment={comment}
                  onSave={handleAfterSave}
                />
              ))}
            </Box>
            <Box px={4}>
              <CommentForm
                comment={{ postId: post.id }}
                onSave={handleAfterSave}
              >
                {({ open }) => <FormMockButton onClick={open} />}
              </CommentForm>
            </Box>
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default Comments;
