import React from "react";

import Box from "components/base/Box";

import { size } from "theme";

const SingleLayout = props => (
  <Box mx="auto" style={{ maxWidth: size.contentWidth }} {...props} />
);

export default SingleLayout;
