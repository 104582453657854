import React from "react";

import Query from "graphql/Query";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import Pagination, { DEFAULT_PAGE_SIZE } from "components/common/Pagination";
import ListItem from "./ListItem";

import { extractEdgeNodes } from "graphql/utils";
import { isLoggedin } from "lib/helper/auth";
import paths from "paths";

import { MY_COMMENTED_POSTS } from "../queries";

const EmptyNotice = () => {
  return (
    <Flex center flexDirection="column" py="50px" px={4}>
      <Text bold fontSize="1.3rem" color="tin" textAlign="center" mb={4}>
        아직 작성하신 답변이 없네요!
      </Text>
      <Text fontSize="0.8rem" color="tin" textAlign="center" px={4}>
        비슷한 고민을 하셨던 분들에게 작은 팁을 주시겠어요?
        <br />
        우리 같이 한번 고민해봐요!
      </Text>
      <Button to={paths.posts.index()} mt={6}>
        답변을 기다리는 질문 보러가기
      </Button>
    </Flex>
  );
};

const MyCommentedPostList = () => {
  if (!isLoggedin()) return null;

  return (
    <Query query={MY_COMMENTED_POSTS} variables={{ first: DEFAULT_PAGE_SIZE }}>
      {({ data, fetchMore }) => {
        const posts = extractEdgeNodes(data.me.commentedPosts);

        if (posts.length === 0) return <EmptyNotice />;

        return (
          <React.Fragment>
            {posts.map(post => (
              <ListItem key={post.id} post={post} />
            ))}
            <Pagination
              data={data}
              dataPath="me.commentedPosts"
              fetchMore={fetchMore}
              mt={2}
            />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default MyCommentedPostList;
