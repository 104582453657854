import React, { useState } from "react";
import { parse, format } from "fecha";
import { Mutation } from "react-apollo";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import Icon from "components/base/Icon";
import Popover from "@material-ui/core/Popover";

import CommentForm from "./CommentForm";

import { DELETE_COMMENT } from "./queries";

const REGEX = /(https?:\/\/[^\s]+)/g;
const renderLink = text => {
  const parts = text.split(REGEX);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <a
        rel="noopener noreferrer"
        target="_blank"
        key={"link" + i}
        href={parts[i]}
        style={{ color: "blue", textDecoration: "underline" }}
      >
        {parts[i]}
      </a>
    );
  }
  return parts;
};

const Comment = ({ comment, specItemId, onSave, onClickReply }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;

  const handleEllipsisClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = mutate => {
    if (!window.confirm("댓글을 삭제하시겠습니까?")) return;

    return mutate({
      variables: { specItemId: specItemId, id: comment.id }
    })
      .then(({ data: { deletePostComment: payload } }) => {
        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        onSave();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Flex
      key={comment.id}
      bg={comment.hasParent ? "concrete" : "white"}
      py={4}
      borderBottom="1px solid"
      borderColor="mercury"
    >
      {comment.hasParent && (
        <Text width="20px" color="tin">
          ㄴ
        </Text>
      )}
      <CommentForm
        comment={{ specItemId, ...comment }}
        onSave={() => {
          handlePopOverClose();
          onSave(true);
        }}
      >
        {({ open }) => (
          <Box flex={1}>
            <Flex justifyContent="space-between" alignItems="center" mb={1}>
              <Flex center>
                <Icon color="tin" className="fa fa-user-circle" mr={1} />
                <Text bold color="black">
                  {comment.user.name}
                </Text>
              </Flex>
              <Flex alignItems="center" fontSize="0.8rem" color="tin">
                {comment.user.currentUser && comment.status === "ACTIVE" && (
                  <React.Fragment>
                    <Box
                      pl={4}
                      fontSize="0.9rem"
                      minHeight="16px"
                      onClick={handleEllipsisClick}
                    >
                      <Icon className="fa fa-ellipsis-v" />
                    </Box>
                    <Popover
                      id={id}
                      open={popOverOpen}
                      anchorEl={anchorEl}
                      onClose={handlePopOverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                    >
                      <Button
                        as="a"
                        paint="link"
                        fontSize="0.9rem"
                        py={3}
                        px={5}
                        onClick={open}
                      >
                        수정
                      </Button>
                      <Mutation mutation={DELETE_COMMENT}>
                        {mutate => {
                          return (
                            <Button
                              as="a"
                              paint="link"
                              fontSize="0.9rem"
                              py={3}
                              px={5}
                              onClick={() => handleDelete(mutate)}
                            >
                              삭제
                            </Button>
                          );
                        }}
                      </Mutation>
                    </Popover>
                  </React.Fragment>
                )}
              </Flex>
            </Flex>
            <Text
              fontSize="0.9rem"
              lineHeight="130%"
              textAlign="justify"
              color="stone"
              style={{
                textDecoration:
                  comment.status === "DELETED" ? "line-through" : "inherit",
                wordBreak: "break-all"
              }}
            >
              {comment.content.split("\n").map((line, index) => {
                return (
                  <React.Fragment key={index}>
                    {line.match(REGEX) ? renderLink(line) : line}
                    <br />
                  </React.Fragment>
                );
              })}
            </Text>
            <Text color="tin" fontSize="0.75rem" py={2}>
              {format(
                parse(comment.createdAt, "YYYY-MM-DDTHH:mm:ssZ"),
                "MM/DD HH:mm"
              )}
            </Text>
            <Flex>
              <Button
                paint="soft"
                borderRadius={0}
                borderColor="silver"
                fontSize="0.8rem"
                p="2px 8px"
                lineHeight="1.5"
                minWidth={0}
                onClick={onClickReply}
              >
                답글
              </Button>
            </Flex>
          </Box>
        )}
      </CommentForm>
    </Flex>
  );
};

export default Comment;
