import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

import Box from "components/base/Box";
import SpecDetail from "views/Specs/components/SpecDetail";

export const Title = styled(Typography)`
  flex: 1;
  justify-content: space-between;
`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const SpecDialog = ({ specItem, onClose }) => (
  <Dialog
    fullScreen
    open={!!specItem}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <AppBar>
      <Toolbar>
        <Title variant="h6" color="inherit">
          기능 상세보기
        </Title>
        <IconButton color="inherit" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Box pt="55px">{specItem && <SpecDetail specItemId={specItem.id} />}</Box>
  </Dialog>
);

export default SpecDialog;
