const theme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    // MUI
    primary: { main: "#497bde" }, // Purple and green play nicely together.
    secondary: { main: "#ff686e" }, // This is just green.A700 as hex.
    mediumDark: "#555",
    medium: "#777",
    light: "#999",
    extraLight: "#ddd",
    blue: "#07c",
    skyBlue: "#1b95e0",
    lightgray: "#f6f6ff",
    green: "#0abe16",
    sealBrown: "#3B1E1E",
    stone: "#333E48",
    charcoal: "#474747",
    zambezi: "#5a5a5a",
    tin: "#7F7F7F",
    gray58: "#949494",
    silver: "#C5C5C5",
    lightgrey: "#D7D7D7",
    mercury: "#E5E5E5",
    solitude: "#EAECEF",
    concrete: "#F7F7F7",
    ghost: "#FAFCFE",
    white: "#FFFFFF",
    // notify
    danger: "#e5002a",
    warning: "orange",
    // vendors
    kakaoYellow: "#F6E218",
    kakaoBrown: "#3C1D1E"
  },
  space: [0, 4, 8, 12, 16, 20, 24, 32, 64, 128, 256],
  fonts: {
    sans: "system-ui, sans-serif",
    mono: "Menlo, monospace"
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    medium: "0 0 12px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  },
  // MUI
  typography: { useNextVariants: true }
};

export const colors = theme.colors;
export const size = {
  contentWidth: "800px",
  modelImageWidth: "250px"
};

export default theme;
