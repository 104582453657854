import gql from "graphql-tag";

export const MODEL_WITH_TRIMS = gql`
  query getModelWithTrims($id: ID!) {
    model(id: $id) {
      id
      name
      representImage {
        small
      }
      modelCategories {
        id
        name
        specItems {
          id
          name
          trimIds
          specGroupIds
        }
      }
      grades {
        id
        name
        trims {
          id
          name
          price
        }
      }
      trims {
        id
        name
        price
        specGroupIds
        grade {
          name
        }
        specGroups {
          id
          name
          price
          index
          specItems {
            id
            name
          }
        }
      }
    }
  }
`;
