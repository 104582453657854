import React from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import ManufacturerLogo from "components/manufacturers/ManufacturerLogo";
import { manufacturerName } from "components/manufacturers/manufacturers";

const defaultCarImage = require("assets/default_car.png");

const Gradient = () => (
  <Box
    position="absolute"
    top="0"
    width="100%"
    pt="50%"
    style={{
      background: "linear-gradient(to top, transparent, rgba(0, 0, 0, 0.8))"
    }}
  />
);

const Cover = ({ model }) => {
  const coverImage =
    model.images.find(i => i.imageableSubtype === "cover") ||
    model.images.find(i => i.imageableSubtype === "represent");

  return (
    <Box position="relative">
      <img alt={model.name} src={defaultCarImage} width="100%" />
      {coverImage && (
        <img
          alt={model.name}
          src={coverImage.cover}
          width="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      )}
      <Gradient />
      <Box position="absolute" top="10%" left="24px">
        <Flex alignItems="center" ml="-7px">
          <ManufacturerLogo id={model.manufacturerId} white />
          <Text fontSize="1.1rem" color="white">
            {manufacturerName(model.manufacturerId)}
          </Text>
        </Flex>
        <Text fontSize="1.8rem" fontWeight="bold" color="white">
          {model.name}
        </Text>
      </Box>
    </Box>
  );
};

export default Cover;
