import React, { useRef, useEffect, useState } from "react";

import Box from "components/base/Box";
import Button from "components/base/Button";

const Gradient = () => (
  <Box
    position="absolute"
    bottom="0"
    left="0"
    width="100%"
    height="100px"
    style={{
      background:
        "linear-gradient(to bottom, transparent, rgba(255, 255, 255, 1))"
    }}
  />
);

const Intro = ({ model, ...rest }) => {
  if (!model.introText) return null;

  const [openAll, setOpenAll] = useState(true);
  const contentBoxRef = useRef(null);

  useEffect(() => {
    if (contentBoxRef.current.clientHeight > 200) {
      setOpenAll(false);
    }
  }, []);

  return (
    <Box bg="white" {...rest}>
      <Box
        position="relative"
        height={!openAll ? "200px" : null}
        style={{ overflowY: "hidden" }}
      >
        <Box
          ref={contentBoxRef}
          dangerouslySetInnerHTML={{ __html: model.introText }}
        />
        {!openAll && <Gradient />}
      </Box>
      {!openAll && (
        <Button
          paint="softly"
          padding="2px 6px"
          onClick={() => setOpenAll(true)}
        >
          더 보기
        </Button>
      )}
    </Box>
  );
};

export default Intro;
