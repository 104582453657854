import gql from "graphql-tag";

export const PostComment = gql`
  fragment PostCommentFields on PostComment {
    id
    content
    threadId
    hasParent
    status
    createdAt
    user {
      name
      currentUser
    }
  }
`;

export const fragments = {
  PostComment
};
