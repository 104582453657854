import React from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import CurrencyFormat from "components/base/CurrencyFormat";
import GraphItem from "components/compare/GraphItem";
import SpecGroup from "./SpecGroup";

import { size } from "theme";

import compareBg from "assets/compare_bg.png";

const TreeColumns = ({
  children,
  left,
  right,
  centerWidth = "80px",
  ...rest
}) => {
  return (
    <Flex fontSize="0.9rem" mb={2} {...rest}>
      <Box textAlign="center" flex="1">
        {left}
      </Box>
      <Flex
        center
        width={centerWidth}
        fontSize="0.8rem"
        color="gray58"
        fontWeight="normal"
      >
        {children}
      </Flex>
      <Box textAlign="center" flex="1">
        {right}
      </Box>
    </Flex>
  );
};

const ModelImage = ({ model }) => {
  if (!model.representImage) return null;

  return (
    <img
      alt={model.name}
      src={model.representImage.small}
      style={{ maxWidth: size.modelImageWidth }}
      width="90%"
    />
  );
};

const ModelImages = ({ c1, c2 }) => {
  const style = {
    backgroundImage: `url(${compareBg})`,
    backgroundPosition: "center 85%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  };
  return (
    <TreeColumns
      left={<ModelImage model={c1.model} />}
      right={<ModelImage model={c2.model} />}
      centerWidth="40px"
      style={style}
    >
      <Text center bold color="tin" fontSize="1.5rem">
        VS
      </Text>
    </TreeColumns>
  );
};

const CompareCard = ({ comparison }) => {
  const c1 = comparison.combination1;
  const c2 = comparison.combination2;

  return (
    <Box>
      <TreeColumns
        left={<Text>{c1.model.manufacturer.name}</Text>}
        right={<Text>{c2.model.manufacturer.name}</Text>}
      />
      <TreeColumns
        mb={0}
        left={
          <Text bold fontSize="1.1rem">
            {c1.model.name}
          </Text>
        }
        right={
          <Text bold fontSize="1.1rem">
            {c2.model.name}
          </Text>
        }
      />
      <ModelImages c1={c1} c2={c2} />
      <TreeColumns
        left={
          <Box>
            <Text color="tin" mb={1}>
              {c1.grade.name}
            </Text>
            <Text fontSize="1rem">{c1.trim.name}</Text>
          </Box>
        }
        right={
          <Box>
            <Text color="tin" mb={1}>
              {c2.grade.name}
            </Text>
            <Text fontSize="1rem">{c2.trim.name}</Text>
          </Box>
        }
      >
        등급
      </TreeColumns>
      <GraphItem
        values={[c1.grade.mileage, c2.grade.mileage]}
        name="연비 km/ℓ"
      />
      <GraphItem values={[c1.grade.hp, c2.grade.hp]} name="출력 hp" />
      <GraphItem values={[c1.grade.torque, c2.grade.torque]} name="토크 kg.m" />
      <GraphItem
        values={[c1.trim.price, c2.trim.price]}
        name="기본가격"
        currency
      />
      <Box borderBottom="1px solid" borderColor="mercury" pb={3} mb={3} />
      <TreeColumns>선택옵션</TreeColumns>
      <Flex borderBottom="1px solid" borderColor="mercury" pb={3}>
        {c1.specGroups && c1.specGroups.length > 0 ? (
          <Box flex={1} pr={4} pl={2}>
            {c1.specGroups.map(specGroup => (
              <SpecGroup key={specGroup.id} specGroup={specGroup} theme={1} />
            ))}
          </Box>
        ) : (
          <Flex center flex={1} color="tin" fontSize="0.7em" pr={3}>
            선택된 옵션 없음
          </Flex>
        )}
        {c2.specGroups && c2.specGroups.length > 0 ? (
          <Box flex={1} pl={4} pr={2}>
            {c2.specGroups.map(specGroup => (
              <SpecGroup key={specGroup.id} specGroup={specGroup} theme={2} />
            ))}
          </Box>
        ) : (
          <Flex center flex={1} color="tin" fontSize="0.7em" pl={3}>
            선택된 옵션 없음
          </Flex>
        )}
      </Flex>
      <TreeColumns
        color="tin"
        fontSize="0.8rem"
        fontWeight="bold"
        pt={3}
        left={<CurrencyFormat short unit="만원" amount={c1.totalGroupPrice} />}
        right={<CurrencyFormat short unit="만원" amount={c2.totalGroupPrice} />}
      >
        선택옵션 가격
      </TreeColumns>
      {c2.totalPrice !== c1.totalPrice && (
        <TreeColumns
          mb={0}
          left={
            c1.totalPrice - c2.totalPrice > 0 && (
              <Text color="red" fontSize="0.8em">
                +
                <CurrencyFormat
                  short
                  unit="만원"
                  amount={c1.totalPrice - c2.totalPrice}
                />
              </Text>
            )
          }
          right={
            c2.totalPrice - c1.totalPrice > 0 && (
              <Text color="red" fontSize="0.8em">
                +
                <CurrencyFormat
                  short
                  unit="만원"
                  amount={c2.totalPrice - c1.totalPrice}
                />
              </Text>
            )
          }
        ></TreeColumns>
      )}
      <TreeColumns
        fontWeight="bold"
        pb={3}
        left={<CurrencyFormat short unit="만원" amount={c1.totalPrice} />}
        right={<CurrencyFormat short unit="만원" amount={c2.totalPrice} />}
      >
        총 가격
      </TreeColumns>
    </Box>
  );
};

export default CompareCard;
