import React, { useState, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";

import withUrlQuery from "components/route/withUrlQuery";
import { TemporaryInfoContext } from "lib/contexts/TemporaryInfoContext";
import withKakao from "lib/hocs/withKakao";
import paths from "paths";

import Drawer from "@material-ui/core/Drawer";
import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import Icon from "components/base/Icon";

import { isLoggedin } from "lib/helper/auth";

import { CREATE_OR_FIND_COMPARISON } from "./queries";

const ShareDrawer = ({ location, history, trimIds = [], groupIds = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { info, setInfo } = useContext(TemporaryInfoContext);

  const url = paths.domain + location.pathname + location.search;

  const handeCreateComparison = mutate => {
    return mutate({
      variables: { trimIds: trimIds, specGroupIds: groupIds }
    })
      .then(({ data: { createComparison: payload } }) => {
        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        setInfo({
          writingPost: {
            ...info.writingPost,
            comparisonId: payload.comparison.id
          }
        });

        history.push({ pathname: paths.posts.write() });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSendToKakao = () => {
    window.Kakao.Link.sendDefault({
      objectType: "text",
      text: "공유된 차량 비교 결과를 확인해보세요.",
      link: {
        mobileWebUrl: url,
        webUrl: url
      },
      buttonTitle: "full-option.com 에서 확인하기"
    });
  };

  return (
    <React.Fragment>
      <Button paint="link" onClick={() => setIsOpen(true)}>
        <Text color="zambezi">
          <Icon className="fa fa-share-alt" mr={1} />
        </Text>
        <Text color="zambezi">공유</Text>
      </Button>
      <Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box p={6}>
          {isLoggedin() && trimIds.length > 1 ? (
            <Mutation mutation={CREATE_OR_FIND_COMPARISON}>
              {mutate => (
                <Flex
                  alignItems="center"
                  mb={7}
                  onClick={() => handeCreateComparison(mutate)}
                >
                  <Icon
                    className="far fa-question-circle"
                    color="zambezi"
                    fontSize="36px"
                    mr={3}
                  />
                  <Text bold color="zambezi">
                    묻고답하기에 질문하기
                  </Text>
                </Flex>
              )}
            </Mutation>
          ) : null}
          <Flex alignItems="center" mb={7} onClick={() => handleSendToKakao()}>
            <Icon.Image name="kakaoTalk" size={36} mr={3} />
            <Text color="zambezi">카카오톡으로 공유하기</Text>
          </Flex>
          <CopyToClipboard
            text={url}
            onCopy={() =>
              window.alert(
                "링크를 복사했습니다.\n이제 원하는 곳에 공유해보세요!"
              )
            }
          >
            <Flex alignItems="center" mb={3}>
              <Icon
                className="far fa-copy"
                color="zambezi"
                fontSize="36px"
                mr={3}
              />
              <Text color="zambezi">링크 복사하기</Text>
            </Flex>
          </CopyToClipboard>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

const urlQueryWhiteList = ["trimIds", "groupIds"];

export default withKakao(
  withRouter(withUrlQuery(urlQueryWhiteList)(ShareDrawer))
);
