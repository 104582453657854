const trimMapping = {
  1: "SLBK",
  2: "EN89",
  3: "8UU5",
  4: "AFCU",
  5: "ZZ7A",
  6: "BW34",
  7: "TWJN",
  8: "FQCM",
  9: "M5DJ",
  10: "YNWG",
  11: "7JK5",
  12: "F8SZ",
  13: "PWWJ",
  14: "S8H5",
  15: "ZNPN",
  16: "E569",
  17: "7GDX",
  18: "GZPQ",
  19: "2ZMY",
  20: "ZL8L",
  21: "H8AB",
  22: "CUXT",
  23: "7X4A",
  24: "7VZJ",
  25: "J4MB",
  26: "TKQ5",
  27: "9PU7",
  28: "T349",
  29: "48LM",
  30: "4FV4",
  31: "4FY6",
  32: "LHZ2",
  33: "22AC",
  34: "K32S",
  35: "5V89",
  36: "R8UF",
  37: "WWFK",
  38: "EBXS",
  39: "J6Y8",
  40: "U5K5",
  41: "T5LM",
  42: "MRB8",
  43: "ZHGL",
  44: "A777",
  45: "BBUJ",
  46: "7MSD",
  47: "HMG9",
  48: "MGG4",
  49: "ZGPY",
  50: "48YL",
  51: "KXRZ",
  52: "UE7H",
  53: "KD63",
  54: "2RB7",
  55: "TJKC",
  56: "593F",
  57: "F8YV",
  58: "XUN3",
  59: "LULJ",
  60: "EMUX",
  61: "5UHT",
  62: "KU9S",
  63: "4JLU",
  64: "RRTC",
  65: "QYG5",
  66: "WNEX",
  67: "X5CS",
  68: "33A6",
  69: "6XBL",
  70: "VC39",
  71: "Y8C2",
  72: "QBKB",
  73: "BJGF",
  74: "CSPN",
  75: "NBW8",
  76: "XD2D",
  77: "LWW6",
  78: "CW63",
  79: "W596",
  80: "ZACD",
  81: "JZNQ",
  82: "KUXV",
  83: "YYVQ",
  84: "XYL6",
  85: "SG4T",
  86: "KBVJ",
  87: "9GD3",
  88: "H3XD",
  89: "2WNE",
  90: "LYM8",
  91: "A5T5",
  92: "7HQM",
  93: "4FYA",
  94: "VNF5",
  95: "XSTS",
  96: "FAR7",
  97: "7V9P",
  98: "TBYK",
  99: "9C7G",
  100: "3QKH",
  101: "THTV",
  102: "VHCE",
  103: "9E7W",
  104: "E5DX",
  105: "587B",
  106: "HW2R",
  107: "4QG6",
  108: "3PPT",
  109: "R5CR",
  110: "HYAC",
  111: "X6P4",
  112: "D9TX",
  113: "TP68",
  114: "DQ4E",
  115: "4KFS",
  116: "9PQT",
  117: "GFZT",
  118: "ULB6",
  119: "VUDC",
  120: "SPLQ",
  121: "Y8MV",
  122: "4QJU",
  123: "PY4N",
  124: "PUQD",
  125: "DBUV",
  126: "8GYW",
  127: "5B37",
  128: "QPU2",
  129: "DFMZ",
  130: "NZJJ",
  131: "6269",
  132: "D2VV",
  133: "ATLD",
  134: "UFLW",
  135: "2NC4",
  136: "UWWX",
  137: "HWN5",
  138: "DL7N",
  139: "JP6T",
  140: "VKM2",
  141: "6825",
  142: "C9NT",
  143: "625Z",
  144: "2PYA",
  150: "RD6E",
  151: "MRT7",
  152: "2YGU",
  153: "8SPG",
  154: "2UKH",
  155: "C66C",
  156: "TEC3",
  157: "HBV6",
  158: "46BK",
  159: "VYS3",
  160: "NQ5J",
  161: "5JD2",
  162: "8CNS",
  163: "4AD3",
  164: "9ZHT",
  165: "RB85",
  166: "X7X8",
  167: "T3ZB",
  168: "GFSQ",
  169: "H8M4",
  170: "PGNM",
  171: "RVZ9",
  172: "D3Z6",
  173: "PJ9N",
  174: "A36N",
  175: "55TM",
  176: "DDAL",
  177: "BUY8",
  180: "VKKM",
  181: "WWWX",
  182: "CPPT",
  183: "76R5",
  184: "24QY",
  188: "E5SQ",
  189: "VDUE",
  190: "4BWP",
  191: "YY29",
  192: "KKPX",
  193: "T6R3",
  194: "57U2",
  195: "XCXQ",
  196: "6FAR",
  197: "W2GA",
  198: "KN2L",
  199: "RDPK",
  200: "JF8H",
  201: "8YCT",
  202: "CYES",
  203: "CJ4L",
  204: "GAKV",
  205: "HR3Z",
  206: "GF4Z",
  207: "J4LB",
  208: "GXAT",
  209: "WYF2",
  210: "694U",
  211: "4KWR",
  212: "WYWR",
  213: "AE2J",
  214: "QTTU",
  215: "ERJ8",
  216: "LAPK",
  217: "RGDZ",
  218: "CQT4",
  219: "VE59",
  220: "TAXV",
  221: "8SA9",
  222: "2E3L",
  223: "GRY5",
  224: "4B4X",
  225: "RA62",
  226: "HYTL",
  227: "DNV6",
  228: "HKQP",
  229: "AN2J",
  230: "56CA",
  231: "L8MR",
  232: "9LKN",
  233: "Q8DY",
  234: "LV5E",
  235: "GUMF",
  236: "8LD2",
  237: "C5PD",
  238: "YVWB",
  239: "JTF3",
  240: "N3GS",
  241: "VWCQ",
  242: "JG9Y",
  243: "ZJRA",
  244: "GXQQ",
  245: "XP44",
  246: "37SK",
  247: "QF2R",
  248: "47VX",
  249: "MKDD",
  250: "G6E8",
  251: "B3JB",
  252: "VZRV",
  253: "AWVL",
  254: "L82Z",
  255: "BGTX",
  256: "MSUB",
  257: "NFXJ",
  258: "GJ6X",
  259: "LWMQ",
  260: "NEWN",
  261: "AUQX",
  262: "7QT9",
  263: "U9MC",
  265: "VT7S",
  266: "6JAL",
  268: "PTTN",
  269: "JJSQ",
  270: "FX2Y",
  271: "6CE4",
  272: "BCV9",
  273: "QRYW",
  274: "75YB",
  275: "4NR6",
  276: "N87S",
  277: "MXTS",
  278: "LCJ5",
  279: "UKUS",
  280: "H62A",
  281: "QZ6N",
  282: "ULKX",
  283: "VCL6",
  284: "JC4G",
  285: "W4CU",
  286: "EMS9",
  287: "5PZ5",
  288: "J7FW",
  289: "YUNH",
  290: "V6ZJ",
  291: "7G9P",
  292: "Z23N",
  293: "MQE5",
  294: "3B8N",
  295: "RSFZ",
  296: "4NZS",
  297: "WH93",
  298: "BVJT",
  299: "4TU6",
  300: "CRWE",
  301: "DTNE",
  302: "S8LW",
  303: "HHFH",
  304: "9BP7",
  305: "5X6R",
  306: "EE4W",
  307: "MSGF",
  308: "BQFW",
  309: "KYSB",
  310: "TSCA",
  311: "D33C",
  312: "EQ9C",
  313: "PCSS",
  314: "WFHZ",
  315: "TNRB",
  316: "JHQT",
  317: "EMDG",
  318: "CB44",
  319: "L29L",
  320: "62W6",
  321: "VGUF",
  322: "T4UV",
  325: "2MY2",
  326: "UCMC",
  327: "39M7",
  328: "R67M",
  329: "ZFDB",
  330: "Q838",
  331: "E4GA",
  332: "X68N",
  333: "D9UD",
  334: "UGVN",
  335: "55N9",
  336: "8K4R",
  337: "8VC4",
  338: "R9WA",
  339: "XWB7",
  340: "G872",
  341: "23VU",
  342: "2B3T",
  343: "ZHUL",
  344: "KVR9",
  345: "HH7T",
  346: "HM9X",
  347: "93AF",
  348: "J6DP",
  349: "L5CE",
  350: "SKL9",
  351: "SQBX",
  352: "ZMVD",
  353: "BY6Y",
  354: "33F2",
  356: "4J88",
  357: "ECR7",
  358: "TY84",
  359: "RQRR",
  364: "FED7",
  365: "8VXQ",
  366: "3D2B",
  367: "PWVU",
  368: "ZGCD",
  369: "KF4K",
  370: "3HSX",
  371: "W9R5",
  372: "C8TM",
  373: "5WV7",
  374: "EWPZ",
  375: "KHLV",
  376: "X6FB",
  377: "W9RN",
  378: "28PK",
  380: "W6V5",
  381: "U72V",
  382: "S8TW",
  383: "86GU",
  384: "JS4U",
  385: "3SZL",
  386: "866M",
  387: "S3QH",
  388: "AHUF",
  389: "CMWZ",
  390: "CVVP",
  391: "2VVX",
  392: "N47P",
  399: "NHDR",
  400: "F7SP",
  403: "RFKT",
  416: "DZPT",
  417: "QGTF",
  418: "5XYF",
  419: "62D6",
  420: "8FKX",
  421: "EYC4",
  422: "B4P9",
  423: "JZHD",
  424: "WCZ4",
  425: "BVHB",
  426: "FBHS",
  427: "9UPB",
  428: "7X3U",
  429: "ACCY",
  430: "7D7F",
  431: "U3VS",
  432: "V4JQ",
  433: "ZRU6",
  434: "C34X",
  435: "7H7Z",
  436: "PGDJ",
  437: "K39E",
  438: "AZ4R",
  439: "J7BE",
  440: "H4KK",
  441: "3J9C",
  442: "Y5E4",
  443: "AH5M",
  444: "WLEM",
  445: "E78A",
  446: "BCC2",
  447: "VFWJ",
  448: "F2BV",
  449: "8YY2",
  450: "AJZ7",
  451: "4DT9",
  452: "FCWD",
  454: "2XWN",
  456: "PNUU",
  457: "HAAL",
  458: "BQ6S",
  459: "XNPX",
  464: "VLNE",
  467: "JFEF",
  468: "P8JX",
  476: "RS4F",
  477: "6WCD",
  478: "FZX7",
  479: "SGJ9",
  480: "25AB",
  481: "44LA",
  482: "6QN8",
  483: "KE68",
  484: "ZDQT",
  485: "GCEP",
  491: "K6QF",
  492: "PHR9",
  493: "LKV7",
  494: "THWG",
  499: "DYD9",
  500: "7UGK",
  501: "XRTE",
  502: "Z7TS",
  503: "CZP3",
  504: "ZCGS",
  505: "V6T5",
  506: "8KSU",
  507: "PJ6Z",
  508: "XXLJ",
  509: "W4XT",
  510: "9TSM",
  511: "F6U8",
  513: "8FEY",
  514: "Q34X",
  515: "3UB6",
  516: "JXHV",
  517: "888E",
  518: "4SYT",
  519: "Q7EB",
  520: "AEF9",
  521: "535W",
  522: "SRX4",
  523: "5A5G",
  524: "XLHX",
  525: "7P5Y",
  528: "3JD5",
  529: "AG73",
  530: "SXVF",
  531: "8SQL",
  532: "LXNP",
  533: "GG26",
  534: "2PBB",
  536: "27UR",
  537: "S7X2",
  538: "B5YR",
  539: "4GRN",
  540: "DCA9",
  541: "6YE7",
  542: "QVBT",
  544: "FEZQ",
  545: "3QT3",
  546: "LNX4",
  547: "LDP4",
  548: "R47B",
  549: "B7MM",
  550: "S6ST",
  551: "GSQY",
  552: "MCN9",
  561: "YZ87",
  562: "4DMZ",
  563: "NVMA",
  564: "WTQ5",
  566: "5B7L",
  567: "979G",
  568: "9RKR",
  569: "H6XZ",
  570: "JMWM",
  571: "X2GW",
  572: "JT3Z",
  573: "FWKT",
  574: "LYGT",
  575: "F3TF",
  576: "976S",
  578: "G3YM",
  579: "BMD7",
  580: "J8E3",
  581: "ZTA9",
  582: "LGDB",
  583: "U4B2",
  585: "J8AM",
  586: "N9V4",
  587: "S4CM",
  588: "MUF3",
  589: "CU7B",
  590: "ECVE",
  591: "JPXC",
  592: "XU39",
  593: "YSAH",
  594: "ZCK7",
  595: "ET2P",
  596: "K8CR",
  597: "BWWP",
  598: "YL5T",
  599: "LNRC",
  600: "WBXR",
  601: "NUUT",
  602: "QMJG",
  603: "VM5T",
  604: "N6MU",
  605: "6W34",
  606: "F8BH",
  607: "FAAR",
  610: "972A",
  611: "HDTK",
  612: "5B3T",
  613: "JPZC",
  614: "YE5G",
  615: "R7H8",
  616: "G4RK",
  617: "QXK6",
  618: "BHK9",
  619: "7YLS",
  620: "WLH9",
  621: "GADE",
  622: "59HX",
  623: "8946",
  624: "TAZY",
  625: "YKX4",
  626: "XJXK",
  627: "MA59",
  628: "KUUV",
  629: "GGD7",
  630: "RTV9",
  631: "4J4V",
  632: "5XKK",
  635: "6987",
  636: "KJVM",
  637: "8GWX",
  638: "YH2P",
  652: "7ZR5",
  653: "86A9",
  654: "QWGR",
  655: "DBRR",
  656: "2Y4F",
  657: "ACC5",
  658: "929S",
  659: "PJPG",
  660: "39PK",
  661: "JJG9",
  662: "UU85",
  663: "TWJX",
  664: "LSNJ",
  665: "H998",
  666: "PWL8",
  667: "YVLN",
  669: "UKVH",
  670: "9U7U",
  671: "9AZ5",
  672: "2BNB",
  673: "SUG4",
  674: "BSK7",
  675: "TJK3",
  676: "LLDZ",
  677: "MFK9",
  678: "VZEH",
  679: "WT5Z",
  680: "NTJK",
  681: "ZJK6",
  682: "76MD",
  683: "EB3A",
  684: "BAYC",
  685: "HMCM",
  686: "KYKH",
  687: "FR7S",
  688: "X77J",
  689: "DSNR",
  690: "5A7J",
  691: "KYHN",
  692: "8WFL",
  693: "QT7U",
  694: "G3BT",
  695: "ZXWR",
  697: "MC7U",
  698: "MNLR",
  699: "AK5F",
  700: "PWEY",
  701: "2USW",
  702: "ZEV9",
  703: "XK73",
  705: "4XRY",
  706: "TDZC",
  707: "3QYT",
  708: "KNF8",
  714: "RK7B",
  715: "YYUK",
  716: "GBZM",
  717: "TCJ7",
  719: "ML5F",
  720: "PNUW",
  721: "C59J",
  722: "M6KQ",
  723: "PTJG",
  724: "NWAB",
  725: "CNL9",
  726: "XDAM",
  727: "3L7N",
  728: "CLSN",
  729: "5HZF",
  730: "L923",
  731: "8H4Y",
  732: "3PNG",
  733: "R84Y",
  734: "QJFM",
  735: "GQB4",
  736: "PHJ2",
  737: "E3RA",
  738: "T42X",
  739: "FE53",
  740: "FGGF",
  741: "4QMC",
  742: "DLZP",
  743: "VDQP",
  744: "6ZSW",
  745: "EVV7",
  746: "2HWP",
  747: "2PVB",
  748: "Z3PD",
  749: "8NLU",
  750: "FCT6",
  751: "VE2F",
  752: "TG4J",
  753: "YSTQ",
  754: "7HQG",
  755: "DARF",
  756: "LC4G",
  757: "EW8F",
  758: "25MX",
  760: "KPXM",
  761: "PTM5",
  762: "B3HK",
  763: "G4MN",
  764: "XFXM",
  765: "5N9Q",
  766: "F3CC",
  767: "UMDA",
  768: "J3NG",
  769: "HR7X",
  770: "9GHD",
  771: "84LY",
  778: "GGRM",
  781: "MWFJ",
  782: "DRCC",
  783: "6SHW",
  784: "CAUB",
  785: "ZLRZ",
  786: "4PMZ",
  787: "LMXN",
  788: "A27M",
  789: "BDVE"
};
export default trimMapping;
