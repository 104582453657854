import React from "react";
import styled from "styled-components";

import {
  color,
  space,
  typography,
  layout,
  flexbox,
  border,
  position,
  shadow
} from "styled-system";

const DefaultBox = styled.div`${color}${space}${typography}${layout}${flexbox}${border}${position}${shadow}`;

const Box = React.forwardRef(({ to, ...props }, ref) => {
  if (to) {
    return <DefaultBox ref={ref} as="a" href={to} {...props} />;
  }

  return <DefaultBox ref={ref} {...props} />;
});

export default Box;
