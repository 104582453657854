import gql from "graphql-tag";

export const MANUFACTURERS = gql`
  query getManufacrurers {
    manufacturers {
      id
      name
      status
      sortWeight
      models {
        id
        name
        year
        representImage {
          small
        }
        trims {
          id
          price
        }
        grades {
          id
          name
          mileage
          torque
          hp
          trims {
            id
            name
            price
          }
        }
      }
    }
  }
`;
