import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

const Wrapper = styled.div`
  margin-right: 4px;
  line-height: 1.52857;
  ${props => (props.showAs === "inline" ? "display: inline-block" : "")};
  ${space}
`;
//line-heigh : 앱 전체로 빼야 함 (body)

const Label = styled.label`
  margin: 0;
  cursor: pointer;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
`;

const InputOverwrap = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 1px solid #ccc;
  margin-right: ${props => (props.showAs === "inline" ? 0 : 5)}px;
  text-align: center;
  font-weight: 600;
  font-size: ${props => props.size / 2 + 3}px;
  border-radius: ${props => (props.shape === "circle" ? "100%" : "2px")};
  line-height: ${props => props.size}px;
  ${props => themeColor(props.theme, props.checked)}
}
`;

const themeColor = (theme, checked) => {
  let color = "";
  switch (theme) {
    case 1:
      color = "#5d9cec";
      break;
    case 2:
      color = "#FF9300";
      break;
    case 3:
      color = "#d366f9";
      break;
    case 4:
      color = "#33b741";
      break;
    default:
      color = "inherit";
  }

  if (checked) {
    return `color: white; border-color: ${color}; background-color: ${color}; `;
  } else {
    return `color: ${color}; border-color: ${color};`;
  }
};

const NumberedCheck = props => {
  const {
    // 색상. input:checked 속성을 사용해 checked, unchecked 스타일을 지정하기 때문에
    // style에 inline으로 직접 색상을 넣기가 어렵다.
    // :not selector는 CSS4 spec이므로 IE에서 지원되지 않는다.
    theme,
    shape, // (none): rounded square, circle: circle
    size,
    showAs,
    style, // main div에 지정하는 스타일
    inputStyle, // input에 직접 지정하는 스타일. 마진등을 조정한다.
    spanStyle, // span에 직접 지정하는 스타일. 마진등을 조정한다.
    number, // 이 프롭이 있으면 해당 숫자(문자)를 표시하고, 없으면 check 기호를 표시한다
    label, // label
    checked, // controlled input checked
    onChange, // onChange event callback
    onClick,
    displayOnly, // 인풋 없이 디스플레이만 할 지 여부
    ...rest
  } = props;

  return (
    <Wrapper
      style={style}
      showAs={showAs}
      onClick={e => {
        if (onClick && !onChange) {
          e.preventDefault();
          return onClick(e);
        }
      }}
      {...rest}
    >
      <Label>
        {!displayOnly && (
          <Input
            type="checkbox"
            style={inputStyle}
            onChange={e => onChange(e.target.checked)}
            checked={checked}
          />
        )}
        <InputOverwrap
          className={number ? "" : !checked ? "" : "fa fa-check"}
          style={spanStyle}
          theme={theme}
          shape={shape}
          showAs={showAs}
          checked={checked}
          size={size}
        >
          {number ? number : ""}
        </InputOverwrap>
        {label}
      </Label>
    </Wrapper>
  );
};

NumberedCheck.defaultProps = {
  size: 18,
  showAs: "block",
  checked: false,
  onChange: () => {}
};

export default NumberedCheck;
