import React, { useState } from "react";

import Query from "graphql/Query";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import Pagination, { DEFAULT_PAGE_SIZE } from "components/common/Pagination";
import ModelBasicCompare from "components/compare/ModelBasicCompare";

import ListItem from "./ListItem";

import { extractEdgeNodes } from "graphql/utils";
import { isLoggedin } from "lib/helper/auth";

import { MY_POSTS } from "../queries";

const EmptyNotice = () => {
  return (
    <Flex center flexDirection="column" py="50px" px={4}>
      <Text bold fontSize="1.3rem" color="tin" textAlign="center" mb={4}>
        아직 작성하신 질문이 없네요!
      </Text>
      <Text fontSize="0.8rem" color="tin" textAlign="center" px={4}>
        아래에서 비교를 원하는 차종을 선택하시고 질문해보시겠어요? 자동차에 관한
        전문가인 풀옵션의 다른 사용자분들이 답변해주실꺼에요!
      </Text>
      <NewCompare />
    </Flex>
  );
};

const NewCompare = ({ showTitle }) => {
  const [trimsToCompare, setTrimsToCompare] = useState([]);
  const comparePath =
    trimsToCompare.length > 1
      ? `/compare?trimIds=${trimsToCompare[0].id}&trimIds=${trimsToCompare[1].id}`
      : "";
  return (
    <Box>
      {showTitle && (
        <Text px={5} bold color="tin">
          질문하기
        </Text>
      )}
      <ModelBasicCompare
        trims={trimsToCompare}
        onChangeTrim={(newTrim, oldTrim) => {
          const newTrimsToCompare = trimsToCompare.slice();
          if (oldTrim) {
            const index = newTrimsToCompare.findIndex(t => t.id === oldTrim.id);
            newTrimsToCompare[index] = newTrim;
          } else {
            newTrimsToCompare.push(newTrim);
          }
          setTrimsToCompare(newTrimsToCompare);
        }}
      />
      <Flex center>
        {trimsToCompare.length > 1 ? (
          <Button py="2px" to={comparePath}>
            비교해보고 질문하기
          </Button>
        ) : (
          <Button py="2px" disabled>
            비교할 모델을 선택해주세요.
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const MyPostList = () => {
  if (!isLoggedin()) return null;

  return (
    <Query query={MY_POSTS} variables={{ first: DEFAULT_PAGE_SIZE }}>
      {({ data, fetchMore }) => {
        const posts = extractEdgeNodes(data.me.posts);

        if (posts.length === 0) return <EmptyNotice />;

        return (
          <React.Fragment>
            <Box p={4} borderBottom="1px solid" borderColor="mercury">
              <NewCompare showTitle={true} />
            </Box>
            {posts.map(post => (
              <ListItem key={post.id} post={post} />
            ))}
            <Pagination
              data={data}
              dataPath="me.posts"
              fetchMore={fetchMore}
              mt={2}
            />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default MyPostList;
