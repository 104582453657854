import storage from "local-storage-fallback";
import paths from "paths";
import history from "browserHistory";
import decodeJwt from "jwt-decode";

const storeAuthToken = auth_token => storage.setItem("auth_token", auth_token);

const loadAuthToken = () => storage.getItem("auth_token");

const resetAuthToken = () => storage.removeItem("auth_token");

const token = loadAuthToken();

if (token) {
  try {
    const { exp } = decodeJwt(token);

    if (new Date().getTime() / 1000 > exp) {
      resetAuthToken();
    }
  } catch (ex) {
    resetAuthToken();
  }
}

export const login = (authToken, afterLoginPath = null) => {
  storeAuthToken(authToken);
  history.replace(afterLoginPath || paths.main());
};

export const logout = (afterLogoutPath = null) => {
  resetAuthToken();
  history.replace(afterLogoutPath || paths.main());
};

export const userInfo = () => {
  const token = loadAuthToken();

  try {
    return decodeJwt(token);
  } catch (error) {
    console.log(error);
  }
};

export const isLoggedin = () => !!loadAuthToken();

export const getAuthToken = () => loadAuthToken();
