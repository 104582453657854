import React from "react";
import styled from "styled-components";

import Flex from "components/base/Flex";
import CurrencyFormat from "../base/CurrencyFormat";

const GraphCell = styled(Flex)`
  font-size: 0.7rem;
  height: 1.1rem;
  & > :nth-child(1) {
    order: ${props => (props.right ? 1 : 0)};
  }
  text-align: ${props =>
    props.center ? "center" : props.right ? "right" : "left"}
  justify-content: ${props =>
    props.center ? "center" : props.right ? "flex-end" : "flex-start"}
`;

const Bar = styled(Flex)`
  font-size: 0.8rem;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: ${props =>
    props.center ? "center" : props.right ? "flex-end" : "flex-start"};
`;

const ModelGraphCell = ({
  right,
  left,
  value = 0,
  otherValue = 0,
  currency = false
}) => {
  if (value !== 0) {
    const baseWidth = 0.4; // 그래프의 최대 길이
    let [defaultWidth, diffWidth] = [0, 0];
    if (otherValue === 0) {
      defaultWidth = 100;
    } else if (value === 0) {
      return <GraphCell flex={1} bg="concrete" right={right} left={left} />;
    } else if (value > otherValue) {
      diffWidth = ((value - otherValue) / value) * 100;
      defaultWidth = 100 - diffWidth;
    } else if (value < otherValue) {
      defaultWidth = (value / otherValue) * 100;
    }

    return (
      <GraphCell flex={1} bg="concrete" right={right} left={left}>
        <Bar
          width={`${defaultWidth * baseWidth}%`}
          minWidth="2rem"
          bg={diffWidth > 0 ? "#f98f8f" : "silver"}
          color="white"
          right={right}
          left={left}
          zIndex={1}
        >
          {currency ? <CurrencyFormat amount={value} unit="" short /> : value}
        </Bar>
        {diffWidth > 0 && (
          <React.Fragment>
            <Bar width={`${diffWidth * baseWidth}%`} bg="#f9a5a5" color="white">
              {/* <Text fontSize="0.8rem" color="red">
                +{(fieldValue - otherFieldValue).toFixed(1)}
              </Text> */}
            </Bar>
          </React.Fragment>
        )}
      </GraphCell>
    );
  }

  return <GraphCell flex={1} bg="concrete" right={right} left={left} />;
};

const GraphItem = ({ values, name, currency = false }) => {
  return (
    <Flex fontSize="0.9rem" mb={2}>
      <ModelGraphCell
        right
        value={values[0]}
        otherValue={values[1]}
        currency={currency}
      />
      <GraphCell center width="80px" color="tin">
        {name}
      </GraphCell>
      <ModelGraphCell
        left
        value={values[1]}
        otherValue={values[0]}
        currency={currency}
      />
    </Flex>
  );
};

export default GraphItem;
