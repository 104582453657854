import React from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";

const Scrap = ({ scrap }) => {
  const scrapUrl = new URL(scrap.url);

  return (
    <Box py={5} borderBottom="1px solid" borderColor="mercury">
      <Flex flexDirection="column">
        <Flex flex={1} mr={2}>
          <a href={scrap.url} rel="noopener noreferrer" target="_blank">
            <Text.Ellipsis fontSize="0.9rem" height="2.3rem" mb={2} lines={2}>
              {scrap.title}
            </Text.Ellipsis>
          </a>
        </Flex>
        <Flex>
          <Text.Ellipsis
            flex="1"
            lines={3}
            fontSize="0.9rem"
            color="tin"
            height="3.2rem"
          >
            {scrap.description}
          </Text.Ellipsis>
          <Flex
            ml={3}
            width="70px"
            height="70px"
            justifyContent="center"
            alignItems="center"
            style={{ overflow: "hidden" }}
          >
            {scrap.image && (
              <img alt={scrap.title} height="100px" src={scrap.image.small} />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mt={2}>
        <Text fontSize="0.9rem" color="green" mr={2}>
          {scrap.comment && `▶︎ ${scrap.comment}`}
        </Text>
        <Text fontSize="0.7rem" color="tin" textAlign="right">
          {scrapUrl.hostname.replace("www.", "")}
        </Text>
      </Flex>
    </Box>
  );
};

const Scraps = ({ model, ...rest }) => {
  if (!model.scraps || model.scraps.length === 0) return null;

  return (
    <Box bg="white" {...rest}>
      <Text mb={4} fontSize="1.5rem" fontWeight="bold">
        리뷰 모아보기
      </Text>
      {model.scraps.map(scrap => (
        <Scrap key={scrap.url} scrap={scrap} />
      ))}
    </Box>
  );
};

export default Scraps;
