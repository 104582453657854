import React from "react";

import Box from "components/base/Box";
import Text from "components/base/Text";

const Cover = ({ specItem, specDesc, ...rest }) => {
  return (
    <Box bg="white">
      <Box position="relative">
        <img alt={specItem.name} src={specDesc.image.large} width="100%" />
      </Box>
      <Box {...rest}>
        <Text bold mb={4}>
          {specItem.name}
        </Text>
        <Text fontSize="0.9rem" color="tin">
          {specDesc.content}
        </Text>
      </Box>
    </Box>
  );
};

export default Cover;
