import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";

import Tabs from "./components/Tabs";
import QnAList from "./components/QnAList";
import MyPostList from "./components/MyPostList";
import MyCommentedPostList from "./components/MyCommentedPostList";

import { isLoggedin } from "lib/helper/auth";

const tabItems = [
  { label: "전체", value: 0, loginRequired: false },
  { label: "내질문", value: 1, loginRequired: true },
  { label: "내답변", value: 2, loginRequired: true }
];

const List = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabClick = value => {
    const selected = tabItems.find(i => i.value === value);

    if (!selected) return;

    if (selected.loginRequired && !isLoggedin()) {
      if (window.confirm("로그인이 필요합니다. 로그인 하시겠습니까?")) {
        // TODO: to login page
        return;
      } else {
        return;
      }
    }

    setTabIndex(value);
  };

  return (
    <Box mt={2}>
      <SwipeableViews index={tabIndex} disabled={true}>
        <Flex justifyContent="space-between" alignItems="center" p={4}>
          <Text color="black" fontSize="1.2rem" lineHeight="150%">
            선택이 고민된다면
            <br />
            다른분들의 의견을 들어보세요.
          </Text>
          <Box>
            <Button
              paint="soft"
              py="2px"
              px={2}
              onClick={() => handleTabClick(1)}
            >
              질문하기
            </Button>
          </Box>
        </Flex>
        <Text p={4} color="black" fontSize="1.2rem" lineHeight="150%">
          내 질문들이
          <br />
          답변되었는지 확인해보세요.
        </Text>
        <Text p={4} color="black" fontSize="1.2rem" lineHeight="150%">
          내 답변들입니다.
          <br />
          도움을 주셔서 감사합니다.
        </Text>
      </SwipeableViews>
      <Box textAlign="right" py={3} px={4}>
        <Tabs
          selectedValue={tabIndex}
          onClick={handleTabClick}
          tabItems={tabItems}
        />
      </Box>
      <Box borderTop="1px solid" borderColor="mercury">
        <SwipeableViews index={tabIndex} disabled={true}>
          <QnAList />
          <MyPostList />
          <MyCommentedPostList />
        </SwipeableViews>
      </Box>
    </Box>
  );
};

export default List;
