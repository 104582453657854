import gql from "graphql-tag";

const SpecCategory = gql`
  fragment SpecCategoryFields on SpecCategory {
    id
    name
    notability
    parentId
    sortWeight
    specStandards {
      id
      name
      notability
      specCategoryId
    }
    children {
      id
      name
      notability
      parentId
      sortWeight
      specStandards {
        id
        name
        notability
        specCategoryId
      }
    }
  }
`;

const SpecCategories = gql`
  query getSpecCategories {
    specCategories {
      ...SpecCategoryFields
    }
  }
  ${SpecCategory}
`;

export const queries = {
  SpecCategories
};

export const fragments = {
  SpecCategory
};
