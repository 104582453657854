import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import CurrencyFormat from "components/base/CurrencyFormat";

import { colors } from "theme";
import paths from "paths";

const BORDER_COLOR = "concrete";

const TableWrapper = styled(Box)`
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.9rem;
`;

const Tr = styled.tr``;

const BaseTd = styled.td`
  padding: 0;
  border: 1px solid ${colors.tin};
  border-color: ${colors[BORDER_COLOR]};
`;

const Td = props => (
  <BaseTd>
    <Flex
      p={2}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "30px", minWidth: "6em" }}
      {...props}
    />
  </BaseTd>
);

const GradesTable = ({ model, ...rest }) => {
  const alltrims = model.trims
    .filter(
      trim =>
        !model.trims.find(at => at.name === trim.name && at.price < trim.price)
    )
    .sort((a, b) => a.price - b.price)
    .map(t => t.name);

  return (
    <Box bg="white" {...rest}>
      <Text mb={3} fontSize="1.5rem" fontWeight="bold">
        라인업
      </Text>
      <TableWrapper>
        <Table>
          <tbody>
            <Tr>
              <Td />
              {model.grades.map(grade => (
                <Td key={grade.name} fontSize="0.9em" fontWeight="bold">
                  {grade.name}
                </Td>
              ))}
            </Tr>
            {alltrims.map(trim => (
              <Tr key={trim}>
                <Td fontWeight="bold" fontSize="0.9em">
                  <Text textAlign="center" style={{ wordBreak: "keep-all" }}>
                    {trim}
                  </Text>
                </Td>
                {model.grades.map(grade => {
                  const current = grade.trims.find(t => t.name.trim() === trim);

                  return (
                    <Td key={grade.name} bg={current ? "ghost" : "none"}>
                      {current && (
                        <Link to={paths.trims(model.uniqName, current.id)}>
                          <Text.StyledUnderline lineColor="skyBlue">
                            <CurrencyFormat
                              unit="만원"
                              short={true}
                              fontWeight="bold"
                              amount={current.price}
                            />
                          </Text.StyledUnderline>
                        </Link>
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Box>
  );
};

export default GradesTable;
