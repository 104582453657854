import React, { useState, useRef } from "react";
import Query from "graphql/Query";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";

import Comment from "./Comment";
import CommentForm from "./CommentForm";
import FormMockButton from "./FormMockButton";

import { SPEC_ITEM_COMMENTS } from "./queries";

const Thread = ({ specItemId, comment, onSave }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);

  const lastCommentRef = useRef(null);

  let openReplyFuncInForm = null;

  const handleOpenReply = () => {
    setShowReplyForm(true);
    /*
     * renderProps 패턴으로 구현된 CommentForm의 state를 컨트롤 하기 위해
     * 빈 children을 렌더링 하고 open 메서드를 가져와 Thread 컴포넌트에 저장한다.
     * 이후에 답글 버튼이 눌리면 해당 메서드를 실행해 CommentForm을 연다.
     */
    openReplyFuncInForm && openReplyFuncInForm();

    lastCommentRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <React.Fragment>
      <Comment
        comment={comment}
        specItemId={specItemId}
        onClickReply={handleOpenReply}
        onSave={onSave}
      />
      {comment.children.map(child => (
        <Comment
          key={child.id}
          comment={child}
          specItemId={specItemId}
          onClickReply={handleOpenReply}
          onSave={onSave}
        />
      ))}
      <div ref={lastCommentRef} />
      <Flex
        bg="concrete"
        p={4}
        borderBottom="1px solid"
        borderColor="mercury"
        style={{ display: showReplyForm ? "flex" : "none" }}
      >
        <Text width="20px" color="tin">
          ㄴ
        </Text>
        <CommentForm
          comment={{
            specItemId: specItemId,
            parentId: comment.id
          }}
          onSave={onSave}
          onClose={() => setShowReplyForm(false)}
        >
          {({ open }) => {
            openReplyFuncInForm = open;

            return null;
          }}
        </CommentForm>
      </Flex>
    </React.Fragment>
  );
};

const Comments = ({ specItemId, ...rest }) => {
  return (
    <Query query={SPEC_ITEM_COMMENTS} variables={{ id: specItemId }}>
      {({ data, fetchMore }) => {
        const comments = data.specItem.comments;

        const handleAfterSave = () => {
          return fetchMore({
            variables: {
              specItemId: specItemId,
              isFetchMore: true
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return fetchMoreResult;
            }
          });
        };

        return (
          <Box bg="white" {...rest}>
            <Text mb={5} fontSize="1.5rem" fontWeight="bold">
              사용해보셨나요?
              <br />
              궁금한 점이 있으신가요?
            </Text>
            <Box mb={5}>
              <Text py={2} borderBottom="1px solid" borderColor="mercury">
                {data.specItem.totalCommentsCount > 0
                  ? `댓글 (${data.specItem.totalCommentsCount})`
                  : "첫 의견을 남겨주시겠어요?"}
              </Text>
              {comments.map(comment => (
                <Thread
                  key={comment.id}
                  specItemId={specItemId}
                  comment={comment}
                  onSave={handleAfterSave}
                />
              ))}
            </Box>
            <Box>
              <CommentForm
                comment={{ specItemId: specItemId }}
                onSave={handleAfterSave}
              >
                {({ open }) => <FormMockButton onClick={open} />}
              </CommentForm>
            </Box>
          </Box>
        );
      }}
    </Query>
  );
};

export default Comments;
