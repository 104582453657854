import React, { Component } from "react";

import AppBar from "components/layouts/AppBar";
import MenuDrawer from "components/layouts/MenuDrawer";
import Box from "components/base/Box";

import { size } from "theme";

class MainLayout extends Component {
  state = {
    isMenuOpen: false
  };

  toggleMenuDrawer = open => {
    this.setState({
      isMenuOpen: open
    });
  };

  render() {
    const { isMenuOpen } = this.state;

    return (
      <React.Fragment>
        <AppBar onClickMenu={() => this.toggleMenuDrawer(true)} />
        <MenuDrawer
          isOpen={isMenuOpen}
          onClose={() => this.toggleMenuDrawer(false)}
        />

        <Box mx="auto" style={{ maxWidth: size.contentWidth }}>
          {this.props.children}
        </Box>
      </React.Fragment>
    );
  }
}

export default MainLayout;
