import React from "react";
import styled from "styled-components";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { color, space, typography, layout, border } from "styled-system";

const Default = styled(TextareaAutosize)`
  outline: none;
  border: none;
  padding: 8px;
  font-size: 1rem;
  width: calc(100% - 16px);
  ${color}${space}${typography}${layout}${border}
`;

const TextArea = React.forwardRef((props, ref) => {
  return <Default ref={ref} {...props} />;
});

export default TextArea;
