import React, { useState } from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";
import SpecDialog from "./SpecDialog";

const CARD_WIDTH = "180px";

const Desc = ({ desc, ...rest }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      bg="white"
      flex="0 0 auto"
      width={CARD_WIDTH}
      {...rest}
    >
      <Box position="relative">
        {desc.image && (
          <img
            alt={desc.specItem.name || "기능"}
            src={desc.image.square}
            width={CARD_WIDTH}
            height={CARD_WIDTH}
          />
        )}
        <Text.Ellipsis lines={2} bold fontSize="0.9rem" pt={3}>
          {desc.specItem.name}
        </Text.Ellipsis>
        {desc.specItem.totalCommentsCount > 0 && (
          <Flex
            p={2}
            fontSize="0.8rem"
            fontWeight="bold"
            color="white"
            position="absolute"
            bg="rgba(0, 0, 0, 0.5)"
            style={{ right: 2, top: 2 }}
          >
            <Icon className="far fa-comment-dots" mr={2} />
            댓글 {desc.specItem.totalCommentsCount}개
          </Flex>
        )}
      </Box>
      <Text.Ellipsis
        lines={3}
        color="tin"
        fontSize="0.8rem"
        mt={3}
        style={{ wordBreak: "break-all" }}
      >
        {desc.content}
      </Text.Ellipsis>
      <Text color="tin" fontSize="0.8rem" textAlign="right" mt={3}>
        {"자세히 보기 >"}
      </Text>
    </Flex>
  );
};

const SpecDescs = ({ model, ...rest }) => {
  if (!model.specDescs || model.specDescs.length === 0) return null;

  const [selectedSpec, selectSpec] = useState(null);

  return (
    <Box bg="white" {...rest}>
      <Text mb={4} fontSize="1.5rem" fontWeight="bold">
        기능
        <br />
        하이라이트
      </Text>
      <Flex flexWrap="nowrap" overflowX="auto" pb={3}>
        {model.specDescs.map((desc, index) => (
          <Desc
            key={index}
            desc={desc}
            ml={index === 0 ? 0 : 4}
            onClick={() => selectSpec(desc)}
          />
        ))}
      </Flex>
      <SpecDialog specDesc={selectedSpec} onClose={() => selectSpec(null)} />
    </Box>
  );
};

export default SpecDescs;
