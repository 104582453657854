import React, { useContext } from "react";
import { Mutation } from "react-apollo";
import { parse, format } from "fecha";
import { withRouter } from "react-router-dom";

import Query from "graphql/Query";

import Box from "components/base/Box";
import Button from "components/base/Button";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Header from "components/base/Header";
import CompareCard from "components/posts/CompareCard";

import { TemporaryInfoContext } from "lib/contexts/TemporaryInfoContext";
import { isLoggedin } from "lib/helper/auth";
import paths, { makeCompareUrl } from "paths";

import Comments from "./Comments";

import { POST, DELETE_POST } from "./queries";

const Content = ({ post }) => {
  return (
    <React.Fragment>
      <Text fontSize="1.1rem">{post.title}</Text>
      <Flex flexDirection="row-reverse" justifyContent="space-between" mt={1}>
        <Box fontSize="0.8rem" color="tin">
          <Text inlineBlock color="charcoal">
            {post.user.name}
          </Text>
          <Text inlineBlock px={2}>
            |
          </Text>
          <Text inlineBlock>
            {format(parse(post.createdAt, "YYYY-MM-DDTHH:mm:ssZ"), "MM/DD")}
          </Text>
        </Box>
      </Flex>
      <Text
        fontSize="0.9rem"
        lineHeight="130%"
        textAlign="justify"
        color="charcoal"
        mt={3}
      >
        {post.content.split("\n").map((line, index) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        })}
      </Text>
    </React.Fragment>
  );
};

const Detail = ({
  match: {
    params: { id }
  },
  history
}) => {
  const { setInfo } = useContext(TemporaryInfoContext);

  const handleGoToUpdating = post => {
    setInfo({
      modifyingPost: {
        id: post.id,
        title: post.title,
        content: post.content,
        comparisonId: post.comparison.id
      }
    });

    history.push({
      pathname: paths.posts.write(),
      search: "mode=update"
    });
  };

  const handleDeletePost = (mutate, post) => {
    if (!window.confirm("댓글을 삭제하시겠습니까?")) return;

    return mutate({
      variables: { id: post.id }
    })
      .then(({ data: { deletePost: payload } }) => {
        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        history.push({
          pathname: paths.posts.index()
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Query query={POST} variables={{ id }}>
      {({ data }) => {
        const post = data.post;

        return (
          <Box bg="solitude">
            <Header
              backEnabled
              align="left"
              fontWeight="bold"
              bg="concrete"
              defaultBackPath={paths.posts.index()}
            >
              뒤로가기
            </Header>
            <Box
              bg="white"
              p={4}
              borderBottom="5px solid"
              borderColor="mercury"
            >
              <Content post={post} />
              {isLoggedin() && post.user.currentUser && (
                <Flex justifyContent="flex-end" mt={4}>
                  <Mutation mutation={DELETE_POST}>
                    {mutate => {
                      return (
                        <Button
                          paint="soft"
                          py="2px"
                          color="danger"
                          mr={2}
                          onClick={() => handleDeletePost(mutate, post)}
                        >
                          삭제
                        </Button>
                      );
                    }}
                  </Mutation>
                  <Button
                    paint="soft"
                    py="2px"
                    onClick={() => handleGoToUpdating(post)}
                  >
                    수정하기
                  </Button>
                </Flex>
              )}
            </Box>
            <Box
              p={5}
              bg="white"
              borderBottom="5px solid"
              borderColor="mercury"
            >
              <CompareCard comparison={post.comparison} />
              <Button
                paint="soft"
                padding="2px 6px"
                to={makeCompareUrl(post.comparison)}
              >
                자세히 보기
              </Button>
            </Box>
            <Box py={5} bg="white">
              <Comments post={post} />
            </Box>
          </Box>
        );
      }}
    </Query>
  );
};

export default withRouter(Detail);
