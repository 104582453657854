import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Text from "components/base/Text";

import { useLocalStorage } from "lib/hooks";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: 150
  }
}));

const UTMManagerModal = ({ location, open, onClose }) => {
  const classes = useStyles();
  const { pathname, search } = location;

  const [savedAttributes, setSavedAttributes] = useLocalStorage(
    "savedAttributes",
    {
      utmSource: "",
      utmMedium: "",
      utmCampaign: ""
    }
  );

  const [attributes, setAttributes] = useState({
    ...savedAttributes,
    result: ""
  });

  useEffect(() => {
    setAttributes({
      ...attributes,
      url: "https://www.full-option.com" + pathname + search
    });
  }, [location]);

  const handleChange = (field, event) => {
    const attribute = {};
    attribute[field] = event.target.value;
    setAttributes({ ...attributes, ...attribute });
  };

  const handleSave = () => {
    const result =
      attributes.url +
      (search ? "&" : "?") +
      "utm_source=" +
      attributes.utmSource +
      "&utm_medium=" +
      attributes.utmMedium +
      "&utm_campaign=" +
      attributes.utmCampaign;

    setAttributes({ ...attributes, result });
    setSavedAttributes({
      utmSource: attributes.utmSource,
      utmMedium: attributes.utmMedium,
      utmCampaign: attributes.utmCampaign
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField
              id="url"
              label="url"
              variant="outlined"
              fullWidth
              value={attributes.url}
            />
            <TextField
              id="utm_source"
              label="utm_source"
              variant="outlined"
              className={classes.textField}
              value={attributes.utmSource}
              onChange={e => handleChange("utmSource", e)}
            />
            <TextField
              id="utm_medium"
              label="utm_medium"
              variant="outlined"
              className={classes.textField}
              style={{ marginLeft: "4px" }}
              value={attributes.utmMedium}
              onChange={e => handleChange("utmMedium", e)}
            />
            <TextField
              id="utm_campaign"
              label="utm_campaign"
              variant="outlined"
              className={classes.textField}
              value={attributes.utmCampaign}
              onChange={e => handleChange("utmCampaign", e)}
            />
            <div
              style={{
                display: "inline-block",
                paddingTop: "15px",
                width: "50%",
                textAlign: "right"
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                생성
              </Button>
            </div>
            <TextField
              id="result"
              label="결과"
              variant="outlined"
              fullWidth
              value={attributes.result}
              style={{ marginTop: "8px" }}
            />
            <Text
              fontSize="0.8rem"
              color="tin"
              p={2}
              style={{ wordBreak: "break-all" }}
            >
              {attributes.result}
            </Text>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default UTMManagerModal;
