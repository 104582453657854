import gql from "graphql-tag";

import { fragments as post } from "graphql/queries/post.graphql";

export const POSTS = gql`
  query Posts($first: Int, $last: Int, $after: String, $before: String) {
    posts(first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...SimplePostFields
        }
      }
    }
  }
  ${post.SimplePost}
`;

export const MY_POSTS = gql`
  query MyPosts($first: Int, $last: Int, $after: String, $before: String) {
    me {
      posts(first: $first, last: $last, after: $after, before: $before) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...SimplePostFields
          }
        }
      }
    }
  }
  ${post.SimplePost}
`;

export const MY_COMMENTED_POSTS = gql`
  query MyCommentedPosts(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    me {
      commentedPosts(
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...SimplePostFields
          }
        }
      }
    }
  }
  ${post.SimplePost}
`;
