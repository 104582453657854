import gql from "graphql-tag";

export const SIGNUP = gql`
  mutation signup($email: String!, $password: String!, $name: String!) {
    signup(input: { email: $email, password: $password, name: $name }) {
      authToken
      errors {
        field
        messages
      }
      user {
        email
        name
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      authToken
      errors {
        field
        messages
      }
      user {
        email
        name
      }
    }
  }
`;

export const LOGIN_WITH_KAKO = gql`
  mutation login($accessToken: String!, $name: String) {
    loginWithKakao(input: { accessToken: $accessToken, name: $name }) {
      authToken
      errors {
        field
        messages
      }
      user {
        email
        name
      }
    }
  }
`;
