import React from "react";

import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";

const FormMockButton = ({ onClick, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="mercury"
      p={2}
      flex={1}
      onClick={onClick}
      {...rest}
    >
      <Text flex={1} color="tin">
        답글을 입력하세요.
      </Text>
      <Button paint="soft" padding="2px 6px" width="50px">
        등록
      </Button>
    </Flex>
  );
};

export default FormMockButton;
