import styled, { keyframes } from "styled-components";
import { Flex } from "rebass";

const defaultCell = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
`;

export const SpecDrawerComponent = styled.div`
  padding: 10px;
  max-width: 800px;
  margin: auto;
`;

export const Trims = styled(Flex)`
  flex-direction: flex-column;
`;

export const CurrentPrice = styled(defaultCell)`
  padding: 0.2rem;
  text-align: center;
  font-size: 1.3rem;
`;

export const GroupPrice = styled(defaultCell)`
  padding: 0.2rem;
  text-align: center;
  font-size: 0.8rem;
  color: blue;
`;

export const BasePrice = styled(defaultCell)`
  padding: 0.2rem;
  text-align: center;
  font-size: 0.8rem;
  color: grey;
`;

export const PriceDiff = styled(defaultCell)`
  padding: 0.2rem;
  text-align: center;
  font-size: 0.8rem;
  color: red;
`;

export const TrimComponent = styled.div`
  flex: 1;
  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;

export const Groups = styled(defaultCell)`
  font-size: 0.7rem;
`;

export const SpecSpecItems = styled.div`
  font-size: 0.5rem;
  color: grey;
`;

// 재미로 넣어봤다
const blink = keyframes`
    0% { color: red;
          text-shadow: red 0px 0px 2px;
       }
    50% { color: blue;
           text-shadow: blue 0px 0px 2px;
       }

    100% { color: red;
           text-shadow: red 0px 0px 2px;
       }
`;

export const SpecSpecItem = styled.span`
  animation: ${blink} 2s linear infinite;
`;

export const SectionHeader = styled(defaultCell)`
  font-size: 0.9rem;
  font-weight: bold;
  color: grey;
  text-align: center;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
`;

export const Footer = styled(defaultCell)`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
`;
