import React, { useContext } from "react";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import { parse as parseQueryString } from "query-string";

import Query from "graphql/Query";
import { TemporaryInfoContext } from "lib/contexts/TemporaryInfoContext";
import paths, { makeCompareUrl } from "paths";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import TextField from "components/base/TextField";
import TextArea from "components/base/TextArea";
import Header from "components/base/Header";
import Button from "components/base/Button";
import CompareCard from "components/posts/CompareCard";

import { CREATE_POST, UPDATE_POST, COMPARISON } from "./queries";
import { POST } from "../Detail/queries";

const WritePost = ({ history, location }) => {
  const { mode } = parseQueryString(location.search);
  const isUpdate = mode === "update";
  const { info, setInfo } = useContext(TemporaryInfoContext);

  const infoField = isUpdate ? "modifyingPost" : "writingPost";
  const attributes = info[infoField];

  const handleChange = (field, event) => {
    const attribute = {};
    attribute[field] = event.target.value;
    setInfo({ [infoField]: { ...attributes, ...attribute } });
  };

  const handleSave = mutate => {
    return mutate({
      variables: attributes,
      refetchQueries: isUpdate
        ? [
            {
              query: POST,
              variables: { id: attributes.id }
            }
          ]
        : []
    })
      .then(({ data }) => {
        const payload = isUpdate ? data.updatePost : data.createPost;

        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        setInfo({ [infoField]: {} });

        history.replace({
          pathname: paths.posts.detail(payload.post.id)
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Query
      useStatic
      query={COMPARISON}
      variables={{ id: attributes.comparisonId }}
    >
      {({ data }) => {
        const comparison = data.comparison;

        return (
          <Mutation mutation={isUpdate ? UPDATE_POST : CREATE_POST}>
            {mutate => {
              return (
                <Box>
                  <Header
                    backEnabled
                    right={
                      <Button
                        padding="2px 6px"
                        onClick={() => handleSave(mutate)}
                      >
                        {isUpdate ? "저장" : "등록"}
                      </Button>
                    }
                  >
                    {isUpdate ? "수정후 저장해주세요" : "묻고답하기 등록"}
                  </Header>
                  <Box p={2} borderBottom="1px solid" borderColor="mercury">
                    <Text fontSize="0.5rem" color="tin" pl={2}>
                      제목
                    </Text>
                    <TextField
                      placeholder="제목을 입력하세요."
                      value={attributes.title}
                      onChange={e => handleChange("title", e)}
                    />
                  </Box>
                  <Box p={2} borderBottom="1px solid" borderColor="mercury">
                    <Text fontSize="0.5rem" color="tin" pl={2}>
                      본문
                    </Text>
                    <TextArea
                      minHeight="100px"
                      placeholder="질문하실 내용을 입력해주세요."
                      value={attributes.content}
                      onChange={e => handleChange("content", e)}
                    />
                  </Box>
                  <Box p={6}>
                    <Flex mb={6} justifyContent="flex-end">
                      <Button
                        paint="soft"
                        padding="2px 6px"
                        to={makeCompareUrl(comparison)}
                      >
                        비교차량 수정
                      </Button>
                    </Flex>
                    <CompareCard comparison={comparison} />
                  </Box>
                </Box>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default withRouter(WritePost);
