import React from "react";
import Slider from "react-slick";
import { stringify } from "query-string";

import paths from "paths";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Button from "components/base/Button";
import CurrencyFormat from "components/base/CurrencyFormat";

const Tags = ({ tags }) => {
  if (!tags) return null;

  const tagsStr = tags
    .split(",")
    .map(tag => `#${tag.trim()}`)
    .join(", ");

  return (
    <Text fontSize={1} color="tin">
      {tagsStr}
    </Text>
  );
};

const ListItem = ({ title, subtitle, price, totalPrice = false, sign }) => {
  return (
    <Flex
      borderBottom={totalPrice ? 0 : "1px solid"}
      borderColor="silver"
      justifyContent="space-between"
      alignItems="flex-end"
      color={totalPrice ? "blue" : "inherit"}
      fontWeight={totalPrice ? "bold" : "inherit"}
      py={2}
    >
      <Box>
        {subtitle && (
          <Text fontSize={2} fontWeight="bold" color="tin">
            {subtitle}
          </Text>
        )}
        <Text fontSize={3} pr={2}>
          {title}
        </Text>
      </Box>
      <Text fontWeight="bold" fontSize={3} style={{ whiteSpace: "nowrap" }}>
        <Text as="span" p={2}>
          {sign ? sign : null}
        </Text>
        <CurrencyFormat amount={price} short unit="만원" />
      </Text>
    </Flex>
  );
};

const GroupListItem = ({ title, price }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-end"
      fontSize="0.7rem"
      color="tin"
      pt={1}
    >
      <Text.Ellipsis pr={2}>{title}</Text.Ellipsis>
      <Text fontWeight="bold" style={{ whiteSpace: "nowrap" }}>
        <Text as="span" p={2}>
          +
        </Text>
        <CurrencyFormat amount={price} short unit="만원" />
      </Text>
    </Flex>
  );
};

// const Label = ({ text }) => (
//   <Box
//     borderRadius={10}
//     bg="mercury"
//     px={2}
//     py={1}
//     maxWidth="90%"
//     style={{
//       display: "inline-block"
//     }}
//   >
//     <Text
//       fontWeight="bold"
//       color="stone"
//       fontSize={2}
//       style={{
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//         whiteSpace: "nowrap"
//       }}
//     >
//       {text}
//     </Text>
//   </Box>
// );

const Description = ({ description }) => {
  return (
    <Box mb={2}>
      {/* <Label text={title} /> */}
      <Text color="charcoal" fontSize={2}>
        {description}
      </Text>
    </Box>
  );
};

const TrimIdea = ({ trimIdea, ...rest }) => {
  const totalPrice =
    trimIdea.trim.price +
    trimIdea.trimIdeaSpecGroups.reduce((sum, g) => sum + g.specGroup.price, 0);
  const pathParams = stringify(
    { groupIds: trimIdea.trimIdeaSpecGroups.map(g => g.specGroup.id) },
    { arrayFormat: "none" }
  );
  const path = `${paths.trims(
    trimIdea.model.uniqName,
    trimIdea.trim.id
  )}?${pathParams}`;

  return (
    <Box
      bg="white"
      p={5}
      maxWidth="250px"
      border="1px solid"
      borderColor="mercury"
      {...rest}
    >
      <Box>
        <Text mb={1}>{trimIdea.subtitle}</Text>
        <Text fontWeight="bold" fontSize={5}>
          {trimIdea.title}
        </Text>
      </Box>
      <Tags tags={trimIdea.tags} />
      <Box bg="white" mt={3}>
        <Box borderBottom="1px solid" borderColor="silver" mb={3}>
          <ListItem
            title={trimIdea.trim.name}
            subtitle={trimIdea.trim.grade.name}
            price={trimIdea.trim.price}
          />
          {trimIdea.trimIdeaSpecGroups.map((group, index) => (
            <GroupListItem
              key={index}
              title={group.specGroup.name}
              price={group.specGroup.price}
            />
          ))}
          <ListItem totalPrice title="총 가격" price={totalPrice} />
        </Box>
        <Box>
          <Description
            title={trimIdea.trim.name}
            description={trimIdea.trimComment}
          />
        </Box>
        <Box style={{ display: "none" }}>
          <Description
            title={trimIdea.trim.name}
            description={trimIdea.trimComment}
          />
          {trimIdea.trimIdeaSpecGroups.map((group, index) => (
            <Description
              key={index}
              title={group.specGroup.name}
              description={group.comment}
            />
          ))}
        </Box>
        <Box mt={2}>
          <Button paint="soft" to={path} display="block">
            자세히 보기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const sliderSettings = {
  className: "slider variable-width",
  infinite: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const TrimIdeas = ({ model, px, ...rest }) => {
  if (!model.trimIdeas || model.trimIdeas.length === 0) return null;

  return (
    <Box {...rest}>
      <Text mx={px} mb={4} fontSize="1.5rem" fontWeight="bold">
        추천트림
      </Text>
      <Slider
        {...sliderSettings}
        centerMode={model.trimIdeas.length === 1 ? true : false}
        variableWidth={model.trimIdeas.length === 1 ? false : true}
      >
        {model.trimIdeas.map(trimIdea => (
          <TrimIdea
            key={trimIdea.title}
            trimIdea={trimIdea}
            // ml={index !== 0 ? 6 : 0}
            ml={px}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default TrimIdeas;
