import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";

import Box from "components/base/Box";
import Text from "components/base/Text";
import Button from "components/base/Button";

import { login } from "lib/helper/auth";
import { LOGIN } from "./queries";

import paths from "paths";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  textField: {
    margin: theme.spacing(1, 0)
  }
}));

const Login = () => {
  const classes = useStyles();
  const [attributes, setAttributes] = useState({});

  const handleChange = (field, event) => {
    const attribute = {};
    attribute[field] = event.target.value;
    setAttributes({ ...attributes, ...attribute });
  };

  const handleSave = mutate => {
    return mutate({
      variables: attributes
    })
      .then(({ data: { login: payload } }) => {
        if (payload.errors) {
          console.log(payload.errors);

          return;
        }

        login(payload.authToken);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Box p={5}>
      <h3>로그인 (임시)</h3>
      <Mutation mutation={LOGIN}>
        {(mutate, { data }) => {
          return (
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                id="email"
                label="email"
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={attributes.email}
                onChange={e => handleChange("email", e)}
              />
              <TextField
                id="password"
                label="password"
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={attributes.password}
                onChange={e => handleChange("password", e)}
              />
              <div
                style={{
                  display: "inline-block",
                  paddingTop: "15px",
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(mutate)}
                >
                  로그인
                </Button>
              </div>
            </form>
          );
        }}
      </Mutation>
      <Text textAlign="center" mt={6}>
        <Link to={paths.auth.signup()}>회원가입</Link>
      </Text>
    </Box>
  );
};

export default Login;
