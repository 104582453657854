import React from "react";
import { withRouter } from "react-router-dom";

import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";

// TODO: 직접 접근 했을 경우는 history.length가 1인 경우도 있음
// react-router-last-location을 써서 해결하던지 하자
const Header = ({
  backEnabled,
  history,
  defaultBackPath,
  right,
  fontWeight,
  align = "center",
  bg = "white",
  ...props
}) => (
  <Flex
    py={2}
    px={3}
    alingItems="center"
    bg={bg}
    borderBottom="1px solid"
    borderColor="mercury"
  >
    {backEnabled && (
      <Icon
        fontSize="1.5rem"
        className="fa fa-chevron-left"
        onClick={() =>
          history.length > 2
            ? history.goBack()
            : history.replace(defaultBackPath)
        }
      />
    )}
    <Text
      flex="1"
      lineHeight="1.8rem"
      textAlign={align}
      fontWeight={fontWeight}
      {...props}
    />
    {right}
  </Flex>
);

export default withRouter(Header);
