import gql from "graphql-tag";

// import { fragments as modelFragments } from "graphql/queries/model.graphql";
// import { fragments as modelCategoryFragments } from "graphql/queries/modelCategory.graphql";
// import { fragments as specItemFragments } from "graphql/queries/specItem.graphql";
import { fragments as specCategoryFragments } from "graphql/queries/specCategory.graphql";

export const TRIMS_AND_CATEGORIES_QUERY = gql`
  query getTrimsAndSpecCategories($ids: [ID!]!) {
    trims(ids: $ids) {
      id
      name
      price
      model {
        id
        name
        uniqName
        representImage {
          small
        }
        grades {
          id
          name
          trims {
            id
            name
            price
          }
        }
        trims {
          id
        }
      }
      grade {
        id
        name
        mileage
        torque
        hp
      }
      specCategories {
        id
        name
        notability
        parentId
        specItems {
          id
          name
          modelId
          modelCategoryId
          notability
          sortWeight
          specGroups {
            id
            trimId
          }
          totalCommentsCount
        }
      }
      specGroups {
        id
        name
        price
        index
        specItems {
          id
          name
        }
      }
    }
    specCategories {
      ...SpecCategoryFields
    }
  }

  ${specCategoryFragments.SpecCategory}
`;

export const CREATE_OR_FIND_COMPARISON = gql`
  mutation($trimIds: [ID!]!, $specGroupIds: [ID!]!) {
    createComparison(
      input: { trimIds: $trimIds, specGroupIds: $specGroupIds }
    ) {
      comparison {
        id
      }
      errors {
        messages
      }
    }
  }
`;
