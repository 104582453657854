import React from "react";
import { Route, Switch } from "react-router-dom";
import gql from "graphql-tag";
import Query from "graphql/Query";

import MANUFACTURERS from "components/manufacturers/manufacturers";

import Models from "./Models";

export const MANUFACTURER = gql`
  query getManufacrurer($id: ID!) {
    manufacturer(id: $id) {
      id
      name
      models {
        id
        name
        uniqName
        year
        representImage {
          small
        }
        trims {
          price
        }
      }
    }
  }
`;

const Manufacturers = ({ match }) => {
  return (
    <Query
      useStatic
      query={MANUFACTURER}
      variables={{ id: MANUFACTURERS[match.params.name].id }}
    >
      {({ data }) => {
        const manufacturer = data.manufacturer;
        return (
          <Switch>
            <Route
              exact
              path={`${match.path}/models`}
              render={props => (
                <Models manufacturer={manufacturer} {...props} />
              )}
            />
          </Switch>
        );
      }}
    </Query>
  );
};

export default Manufacturers;
