import React from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";

import paths from "paths";

const mockImage =
  "/images/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1eb100066be96b0671e766dfff6fad197677eefa/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTXpBd2VETXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--7fbbf511a59c5a350bc6204fd7c95510d15e98ff/venue_2019.png";

const ModelCard = ({ manufacturer, model, tinyMode, ...rest }) => {
  const minPriceTrim = model.trims.reduce(
    (min, trim) => (min.price > trim.price ? trim : min),
    { price: Number.MAX_SAFE_INTEGER }
  );

  const styles = {
    gap: tinyMode ? "5px" : "6px",
    minImageWidth: tinyMode ? "150px" : "160px",
    defaultFontSize: tinyMode ? 2 : 3
  };

  return (
    <Flex
      to={tinyMode ? null : paths.models.index(model.uniqName)}
      flexDirection="column"
      position="relative"
      pt={4}
      mb={3}
      width={`calc(50% - ${styles.gap})`}
      borderRadius={4}
      style={{ overflow: "hidden" }}
      {...rest}
    >
      <Flex position="absolute" flexDirection="column" width={1}>
        {!tinyMode && (
          <Flex justifyContent="space-between" alignItems="center" px={4}>
            <Box fontSize={styles.defaultFontSize - 1}>{manufacturer.name}</Box>
            <Box fontSize={1} color="tin">
              {model.year}
            </Box>
          </Flex>
        )}
        <Box fontSize={styles.defaultFontSize + 1} fontWeight="bold" px={4}>
          {model.name}
        </Box>
        <Box
          fontSize={styles.defaultFontSize}
          fontWeight="bold"
          color="tin"
          px={4}
          mt={styles.defaultFontSize - 1}
        >
          {minPriceTrim.price / 10000}
          <Box as="span" fontSize={1}>
            만원 부터
          </Box>
        </Box>
      </Flex>
      <Box
        pt={54}
        px={styles.defaultFontSize * 2 + 1}
        textAlign="center"
        style={{ height: "120px" }}
      >
        <Box
          position="absolute"
          backgroundColor="rgba(0, 0, 0, 0.05)"
          style={{ top: 0, left: 0, bottom: 0, right: 0 }}
        ></Box>
        <img
          alt={model.name}
          src={model.representImage ? model.representImage.small : mockImage}
          width={styles.minImageWidth}
          style={{ marginBottom: "-5px" }}
        />
      </Box>
    </Flex>
  );
};

export default ModelCard;
