import React, { useState } from "react";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";

const GradeButton = ({ grade, selectedGrade = {}, onClick }) => (
  <Box
    border="1px solid"
    borderColor="lightgrey"
    p={2}
    mr={2}
    bg={selectedGrade.id === grade.id ? "blue" : "white"}
    color={selectedGrade.id === grade.id ? "white" : "inherit"}
    onClick={() => onClick(grade)}
    style={{ wordBreak: "keep-all" }}
  >
    {grade.name}
  </Box>
);

const SpecBox = ({ name, icon, value, unit }) => (
  <Box
    py={4}
    mb={2}
    border="1px solid"
    borderColor="lightgrey"
    bg="white"
    textAlign="center"
    style={{ width: `calc(50% - 6px)` }}
  >
    <Icon.Image name={icon} justifyContent="center" />
    <Text fontSize="0.8rem" color="tin" mt={1}>
      {name}
    </Text>
    <Text>
      {value}
      {unit}
    </Text>
  </Box>
);

const specs = [
  { name: "연료", field: "fuel", icon: "fuel" },
  { name: "연비", field: "mileage", icon: "dispenser", unit: "km/l" },
  { name: "마력", field: "hp", icon: "wheel", unit: "hp" },
  { name: "토크", field: "torque", icon: "engine", unit: "kg.m" },
  { name: "배기량", field: "displacement", icon: "carEngine", unit: "cc" },
  { name: "탑승인원", field: "maxPassengers", icon: "audience", unit: "명" }
];
const Specs = ({ model, ...rest }) => {
  const [selectedGrade, selectGrade] = useState(model.grades[0]);

  return (
    <Box {...rest}>
      <Text fontSize="1.5rem" fontWeight="bold">
        사양
      </Text>
      <Flex mt={4}>
        {model.grades.map(grade => (
          <GradeButton
            key={grade.name}
            grade={grade}
            selectedGrade={selectedGrade}
            onClick={selectGrade}
          />
        ))}
      </Flex>
      {selectedGrade && (
        <Flex mt={4} flexWrap="wrap" justifyContent="space-between">
          {specs.map(spec =>
            selectedGrade[spec.field] ? (
              <SpecBox
                key={spec.name}
                {...spec}
                value={selectedGrade[spec.field]}
              />
            ) : null
          )}
        </Flex>
      )}
    </Box>
  );
};

export default Specs;
