import gql from "graphql-tag";

export const ComparisonCombination = gql`
  fragment CombinationFields on Combination {
    totalPrice
    totalGroupPrice
    model {
      manufacturer {
        name
      }
      name
      representImage {
        small
      }
    }
    grade {
      name
      mileage
      torque
      hp
    }
    trim {
      id
      name
      price
    }
    specGroups {
      id
      name
      price
      sortWeight
      specItems {
        name
      }
    }
  }
`;

export const fragments = {
  ComparisonCombination
};
