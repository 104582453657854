import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";

import Box from "components/base/Box";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import Icon from "components/base/Icon";
import Button from "components/base/Button";

import withKakao from "lib/hocs/withKakao";
import { login } from "lib/helper/auth";
import { LOGIN_WITH_KAKO } from "./queries";

import paths from "paths";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  textField: {
    margin: theme.spacing(1, 0)
  }
}));

const Login = ({ isKakaoLoaded }) => {
  const handleSave = mutate => {
    window.Kakao.Auth.login({
      success: function(authObj) {
        console.log(JSON.stringify(authObj));

        return mutate({
          variables: { accessToken: authObj.access_token }
        })
          .then(({ data: { loginWithKakao: payload } }) => {
            if (payload.errors) {
              console.log(payload.errors);

              return;
            }

            if (!payload.authToken) {
              alert("로그인 중 오류가 발생했습니다.");
            }

            login(payload.authToken);
          })
          .catch(e => {
            console.log(e);
          });
      },
      fail: function(err) {
        alert(JSON.stringify(err));
      }
    });
  };

  return (
    <Box p={5}>
      <Flex flexDirection="column" py="50px" px={5} mb={6}>
        <Text bold fontSize="1.5rem" color="stone" textAlign="left" mb={6}>
          로그인
        </Text>
        <Text fontSize="1rem" color="tin" textAlign="left">
          고민중인 차량에 대해 질문해 보시고,
          <br />
          다른 분들의 고민에도 도움을 주세요!
          <br />
          우리 같이 한번 고민해봐요!
        </Text>
      </Flex>
      {isKakaoLoaded && (
        <Mutation mutation={LOGIN_WITH_KAKO}>
          {(mutate, { data }) => {
            return (
              <Button
                bg="kakaoYellow"
                color="kakaoBrown"
                onClick={() => handleSave(mutate)}
                py={2}
              >
                <Icon className="fa fa-comment" mr={3} />
                <Text bold>카카오로 로그인</Text>
              </Button>
            );
          }}
        </Mutation>
      )}

      <Flex bg="concrete" flexDirection="column" py={6} px={6} mt="100px">
        <Text fontSize="0.8rem" color="tin" textAlign="left">
          카카오를 통한 로그인시에는
          <br />
          중복가입 방지를 위한 이메일(선택사항) 이외의
          <br />
          어떠한 개인정보도 수집하지 않습니다.
          <br />
          <br />
          안심하시고 로그인 하세요.
        </Text>
      </Flex>
    </Box>
  );
};

export default withKakao(Login);
