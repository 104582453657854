import React from "react";

import Flex from "components/base/Flex";
import Box from "components/base/Box";
import ModelInfo from "components/compare/ModelInfo";

import GraphItem from "./GraphItem";

const ModelBasicCompare = ({ trims, onChangeTrim, trimSize = 2, ...rest }) => {
  return (
    <Box {...rest}>
      <Flex mb={2}>
        {new Array(trimSize).fill().map((_, index) => (
          <ModelInfo
            key={index}
            trim={trims[index]}
            onChangeTrim={onChangeTrim}
          />
        ))}
      </Flex>
      {trims && trims.length > 0 && trimSize > 1 && (
        <React.Fragment>
          <GraphItem
            values={trims.map(t => t.grade && t.grade.mileage)}
            name="연비 km/ℓ"
          />
          <GraphItem
            values={trims.map(t => t.grade && t.grade.hp)}
            name="출력 hp"
          />
          <GraphItem
            values={trims.map(t => t.grade && t.grade.torque)}
            name="토크 kg.m"
          />
          <GraphItem
            currency
            values={trims.map(t => t.price)}
            name="가격 (만원)"
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ModelBasicCompare;
