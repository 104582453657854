import React, { useState } from "react";
import CompareSelectDialog from "components/compare/CompareSelectDialog";

const withModelSelector = WrappedComponent => {
  const ModelSelector = props => {
    const [isOpenModelSelector, openModelSelector] = useState(false);
    const [selectedTrim, selectTrim] = useState();

    return (
      <React.Fragment>
        <WrappedComponent
          isOpenModelSelector={isOpenModelSelector}
          openModelSelector={openModelSelector}
          selectedTrim={selectedTrim}
          {...props}
        />
        <CompareSelectDialog
          isOpen={isOpenModelSelector}
          onSelectTrim={selectTrim}
          onClose={() => openModelSelector(false)}
        />
      </React.Fragment>
    );
  };

  return ModelSelector;
};

export default withModelSelector;
