import React from "react";
import { withRouter } from "react-router";

import Query from "graphql/Query";

import Header from "components/base/Header";

import SpecDetail from "./components/SpecDetail";
import paths from "paths";

import { SPEC_ITEM } from "./queries";

const Specs = ({
  match: {
    params: { specItemId }
  }
}) => {
  return (
    <Query useStatic query={SPEC_ITEM} variables={{ id: specItemId }}>
      {({ data }) => {
        const model = data.specItem.model;

        return (
          <React.Fragment>
            <Header
              backEnabled
              align="left"
              fontWeight="bold"
              bg="concrete"
              defaultBackPath={paths.models.index(model.uniqName)}
            >
              {model.name}
            </Header>
            <SpecDetail specItemId={specItemId} />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

export default withRouter(Specs);
