import React, { useState, useEffect } from "react";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Icon from "components/base/Icon";
import Flex from "components/base/Flex";
import Text from "components/base/Text";
import withModelSelector from "components/compare/withModelSelector";

import TrimSelectDrawer from "components/compare/CompareSelect/TrimSelectDrawer";

import { size } from "theme";

// selectedTrim is from withModelSelector
const ModelInfo = ({ trim, onChangeTrim, selectedTrim, openModelSelector }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentTrim = trim;

  useEffect(() => {
    if (selectedTrim) {
      onChangeTrim(selectedTrim, trim);
    }
  }, [selectedTrim]);

  if (currentTrim) {
    return (
      <React.Fragment>
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          onClick={() => setIsOpen(true)}
        >
          {currentTrim.model.representImage && (
            <img
              alt={currentTrim.model.name}
              src={currentTrim.model.representImage.small}
              style={{ maxWidth: size.modelImageWidth }}
              width="80%"
            />
          )}
          <Text color="tin" fontWeight="bold">
            {currentTrim.model.name}
          </Text>
          <Text fontSize="0.8rem" color="tin">
            {currentTrim.grade.name}
          </Text>
          <Flex alignItems="center" pl={2}>
            {currentTrim.name}
            <KeyboardArrowDown />
          </Flex>
        </Flex>

        <TrimSelectDrawer
          isOpen={isOpen}
          model={currentTrim.model}
          onClose={() => setIsOpen(false)}
          onClickTrim={newTrim => {
            onChangeTrim(newTrim, currentTrim);
            setIsOpen(false);
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <Flex
      center
      flex={1}
      flexDirection="column"
      border="1px solid"
      borderColor="mercury"
      justifyContent="center"
      alignItems="center"
      my={3}
      mx={5}
      style={{ minHeight: "100px", minWidth: "100px" }}
      onClick={() => openModelSelector(true)}
    >
      <Icon color="tin" fontSize="2rem" className="fa fa-plus" />
      <Text color="tin" fontSize="0.8rem" textAlign="center" mt={2}>
        비교할 모델
        <br />
        추가하기
      </Text>
    </Flex>
  );
};

export default withModelSelector(ModelInfo);
