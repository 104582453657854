import React from "react";

import Flex from "components/base/Flex";
import Text from "components/base/Text";

import ManufacturerLogo from "components/manufacturers/ManufacturerLogo";
import ModelCard from "components/models/ModelCard";

const Main = ({ manufacturer }) => {
  return (
    <React.Fragment>
      <Flex p={2} alingItems="baseline" bg="concrete">
        <ManufacturerLogo name={manufacturer.name} />
        <Text fontWeight="bold" lineHeight="1.8rem">
          {manufacturer.name}
        </Text>
      </Flex>
      <Flex flexWrap="wrap" justifyContent="space-between" p={3}>
        {manufacturer.models.map(model => (
          <ModelCard key={model.id} manufacturer={manufacturer} model={model} />
        ))}
      </Flex>
    </React.Fragment>
  );
};

export default Main;
