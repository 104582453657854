import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { useLocalStorage } from "lib/hooks";

import Flex from "components/base/Flex";
import Box from "components/base/Box";

import ModelCard from "components/models/ModelCard";

const VerticalTabs = withStyles(() => ({
  root: {
    borderRight: "1px solid #ddd",
    width: "100px"
  },
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  }
}))(Tabs);

const MyTab = withStyles(() => ({
  root: {
    minWidth: "100px"
  },
  selected: {
    color: "tomato",
    borderBottom: "2px solid tomato"
  }
}))(Tab);

const Models = ({ models, index, activeIndex, onClickModel }) => {
  if (activeIndex !== index) {
    return null;
  }

  return (
    <Flex
      key={index}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="stretch"
      py={2}
      px={1}
    >
      {models.map(model => (
        <ModelCard
          key={model.id}
          model={model}
          tinyMode={true}
          onClick={() => onClickModel(model)}
        />
      ))}
    </Flex>
  );
};

const VCarsTab = ({ manufacturers = [], selectedTrim, onClickModel }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // Tool
  const [enableTools, _setEnableTools] = useLocalStorage("enableTools", false); // eslint-disable-line no-unused-vars

  const toRenderMF = selectedTrim
    ? manufacturers.filter(m => m.id === selectedTrim.model.manufacturerId)
    : manufacturers.filter(m => m.status === "active" || enableTools);

  return (
    <Flex borderTop="1px solid #ddd">
      <VerticalTabs
        value={activeIndex}
        onChange={(_, index) => setActiveIndex(index)}
      >
        {toRenderMF.map(manufacturer => (
          <MyTab key={manufacturer.id} label={manufacturer.name} />
        ))}
      </VerticalTabs>

      <Box flex={1}>
        {toRenderMF.map((manufacturer, index) => {
          if (selectedTrim) {
            return (
              <Models
                key={index}
                models={manufacturer.models.filter(
                  m => m.id === selectedTrim.model.id
                )}
                index={activeIndex}
                activeIndex={activeIndex}
                onClickModel={onClickModel}
              />
            );
          }

          return (
            <Models
              key={index}
              models={manufacturer.models}
              index={index}
              activeIndex={activeIndex}
              onClickModel={onClickModel}
            />
          );
        })}
      </Box>
    </Flex>
  );
};

export default VCarsTab;
