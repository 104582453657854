import gql from "graphql-tag";

import { fragments as combination } from "graphql/queries/combination.graphql";

export const PostWithComparison = gql`
  fragment PostWithComparisonFields on Post {
    id
    title
    content
    comparisonLabel
    createdAt
    user {
      name
      currentUser
    }
    comparison {
      id
      combination1 {
        ...CombinationFields
      }
      combination2 {
        ...CombinationFields
      }
    }
  }

  ${combination.ComparisonCombination}
`;

export const SimplePost = gql`
  fragment SimplePostFields on Post {
    id
    title
    comparisonLabel
    createdAt
    user {
      email
      name
    }
    postComments {
      totalCount
    }
  }
`;

export const fragments = {
  SimplePost,
  PostWithComparison
};
