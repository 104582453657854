import { ApolloClient } from "apollo-client";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createHttpLink } from "apollo-link-http";
// import { HttpLink } from "apollo-link-http";
// import { RetryLink } from "apollo-link-retry";
import { ApolloLink, Observable } from "apollo-link";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { getAuthToken } from "lib/helper/auth";

// const isQuery = operation =>
//   operation.query.definitions[0].operation === "query";

const request = operation => {
  const token = getAuthToken();
  const { headers } = operation.getContext();

  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const createClient = endpoint => {
  const link = createPersistedQueryLink({
    useGETForHashedQueries: true
  }).concat(createHttpLink({ uri: endpoint }));

  return new ApolloClient({
    assumeImmutableResults: true,
    link: ApolloLink.from([
      // new RetryLink({
      //   attempts: {
      //     retryIf: (error, operation) => {
      //       const operationIsLogin = operation.operationName.match(/login/);
      //       return !!error && (operationIsLogin || isQuery(operation));
      //     }
      //   }
      // }),
      requestLink,
      link
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: object => {
        switch (object.__typename) {
          case "SpecCategory":
            return null; // SpecCategory는 캐싱을 하지 않음 (Model 아래에 SpecCategory가 다른 모델의 SpecCategory로 덮어써짐)
          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      }
    })
  });
};

export const normalClient = createClient("/graphql");

export const staticClient = createClient("/graphql_static");
