import React from "react";
import { withRouter } from "react-router";

import Box from "components/base/Box";
import Text from "components/base/Text";
import { ModelMeta } from "components/common/Meta";

import Query from "graphql/Query";

import Intro from "./components/Intro";
import Cover from "./components/Cover";
import Specs from "./components/Specs";
import GradesTable from "./components/GradesTable";
import Scraps from "./components/Scraps";
import Compare from "./components/Compare";
import TrimIdeas from "./components/TrimIdeas";
import SpecDescs from "./components/SpecDescs";

import { MODEL } from "./queries";

const Models = ({
  match: {
    params: { id }
  }
}) => {
  return (
    <Query useStatic query={MODEL} variables={{ id }} fetchPolicy="no-cache">
      {({ data }) => {
        const model = data.model;

        if (!data.model) return null;

        const defaultTrim = model.grades[0].trims[0];
        defaultTrim.grade = model.grades[0];
        defaultTrim.model = model;

        return (
          <Box bg="solitude">
            <ModelMeta model={model} />
            <Cover model={model} />
            <Intro model={model} py={5} px={6} />
            <Specs model={model} py={7} px={6} />
            <GradesTable model={model} py={7} px={6} />
            <SpecDescs model={model} py={7} px={6} mt={1} />
            <TrimIdeas model={model} pt={6} pb={7} px={6} />
            <Scraps model={model} py={7} px={6} mt={1} />
            <Compare model={model} py={7} px={6} mt={1} />
            <Box p={7}>
              <Text color="tin">COPYRIGHT© 2020. 풀옵션</Text>
            </Box>
          </Box>
        );
      }}
    </Query>
  );
};

export default withRouter(Models);
