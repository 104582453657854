import gql from "graphql-tag";

import { fragments as postComment } from "graphql/queries/postComment.graphql";
import { fragments as post } from "graphql/queries/post.graphql";

export const POST = gql`
  query Post($id: ID!) {
    post(id: $id) {
      ...PostWithComparisonFields
    }
  }
  ${post.PostWithComparison}
`;

export const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(input: { id: $id }) {
      post {
        title
        content
        createdAt
        user {
          email
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const POST_COMMENTS = gql`
  query PostComments($postId: ID!, $first: Int = 1000) {
    postComments(postId: $postId, first: $first) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...PostCommentFields
          children {
            ...PostCommentFields
          }
        }
      }
    }
  }

  ${postComment.PostComment}
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($postId: ID!, $content: String!, $parentId: ID) {
    createPostComment(
      input: { postId: $postId, content: $content, parentId: $parentId }
    ) {
      postComment {
        ...PostCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${postComment.PostComment}
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $postId: ID!, $content: String!) {
    updatePostComment(input: { id: $id, postId: $postId, content: $content }) {
      postComment {
        ...PostCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${postComment.PostComment}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!, $postId: ID!) {
    deletePostComment(input: { id: $id, postId: $postId }) {
      postComment {
        ...PostCommentFields
      }
      errors {
        field
        messages
      }
    }
  }

  ${postComment.PostComment}
`;
