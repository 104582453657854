import gql from "graphql-tag";

export const MODEL = gql`
  query getModel($id: ID!) {
    model(id: $id) {
      id
      name
      uniqName
      year
      manufacturerId
      introText
      representImage {
        small
        large
      }
      manufacturer {
        name
      }
      images {
        large
        cover
        imageableSubtype
      }
      grades {
        id
        name
        fuel
        mileage
        torque
        hp
        displacement
        maxPassengers
        trims {
          id
          name
          price
        }
      }
      trims {
        id
        name
        price
      }
      scraps {
        contentType
        url
        title
        description
        comment
        createdAt
        image {
          small
        }
      }
      specDescs {
        content
        specItem {
          id
          name
          totalCommentsCount
        }
        image {
          square
          large
        }
      }
      trimIdeas {
        title
        subtitle
        trimComment
        tags
        model {
          uniqName
        }
        trim {
          id
          name
          price
          grade {
            name
          }
        }
        trimIdeaSpecGroups {
          comment
          specGroup {
            id
            name
            price
          }
        }
      }
    }
  }
`;
