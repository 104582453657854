// import _ from "lodash";
// import dateformat from "dateformat";
//
// export function formatDate(date, options = {}) {
//   if (_.isEmpty(date)) return "";
//
//   const currentDate = new Date();
//   const dateObj = date instanceof Date ? date : new Date(date);
//
//   if (options.format) {
//     return dateformat(dateObj, options.format);
//   }
//   if (
//     new Date(currentDate).setHours(0, 0, 0, 0) ===
//     new Date(dateObj).setHours(0, 0, 0, 0)
//   ) {
//     return dateformat(dateObj, "hh:MM:ss");
//   } else {
//     return dateformat(dateObj, "yyyy.mm.dd");
//   }
// }

export function formatCurrency(number, options = { short: false }) {
  if (!number && number !== 0) return "";
  if (options.short) number = parseInt(number / 10000);

  return number.toString().replace(/(\d)(?=(\d{3})+(,|$))/g, "$1,");
}

export function imagePath(path, size) {
  if (!path) return "";
  return path.replace(/\/thumb\//, `/${size}/`);
}

export function formatDuration(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

export function tryObj(value, callback) {
  if (value) {
    return callback(value);
  } else {
    return "";
  }
}

function pad(string) {
  return ("0" + string).slice(-2);
}
